import React from 'react';
import { Route } from 'react-router-dom';
import UnitEdit from './UnitEdit';
import UnitList from './UnitList';

const routes = () =>
  [
    <Route path="/units/new" element={<UnitEdit />} key="/units/new" />,
    <Route path="/units/:id" element={<UnitEdit />} key="/units/:id" />,
    <Route path="/units" element={<UnitList />} key="/units" />,
  ].map((route) => route);

export default routes;
