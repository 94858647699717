import { faSave, faCircleNotch, faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState, useCallback } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FormInputField } from '../../components';
import APIClient from '../../services/APIClient';
import { useToasts } from 'react-toast-notifications';
import Security from '../../services/Security';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from 'react-router-dom';

type UserData = { firstName: string; lastName: string };

export default function UserProfile() {
  const [isUserLoading, setIsUserLoading] = useState<boolean>(true);
  const [user, setUser] = useState<UserData>({ firstName: '', lastName: '' });
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);

  const navigate = useNavigate();

  const { addToast } = useToasts();

  const userId = Security.getSession().user.id;

  const logout = useCallback(() => {
    Security.clearSession();
    navigate('/login');
  }, [navigate]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        let response = await APIClient.get('/me');

        if (response?.data) {
          setUser({
            firstName: response.data.data.firstName,
            lastName: response.data.data.lastName,
          });
        } else {
          console.error('No user data found in response');
          addToast('Error al cargar los datos del usuario. Vuelva a iniciar sesión', {
            appearance: 'error',
          });
          logout();
        }
      } catch (error) {
        console.error('Error fetching user:', error);
        addToast('Error al cargar los datos del usuario.', {
          appearance: 'error',
        });
      } finally {
        setIsUserLoading(false);
      }
    };

    fetchUser();
  }, [addToast, logout]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [id]: value }));
  };

  async function onFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setIsFormSubmitting(true);

    try {
      await APIClient.patch(`/usuarios/${userId}`, user);

      addToast('Sus datos se modificaron correctamente.', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (error) {
      console.error('An error occurred while trying to update the data:', error);
      addToast('Ocurrió un error al intentar actualizar los datos', {
        appearance: 'error',
      });
    } finally {
      setIsFormSubmitting(false);
    }
  }

  return (
    <div>
      <h1 className="page-title">Mi Perfil</h1>

      <Form onSubmit={onFormSubmit}>
        <Row>
          <Col md={6}>
            <FormInputField
              id="firstName"
              label="Nombre"
              value={user.firstName}
              onChange={handleInputChange}
              disabled={isUserLoading || isFormSubmitting}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormInputField
              id="lastName"
              label="Apellido"
              value={user.lastName}
              onChange={handleInputChange}
              disabled={isUserLoading || isFormSubmitting}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col className="mt-4">
            <Button type="submit" variant="primary" disabled={isUserLoading || isFormSubmitting}>
              {isFormSubmitting ? (
                <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin className="me-1" />
              ) : (
                <FontAwesomeIcon icon={faSave} fixedWidth className="me-1" />
              )}
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>

      <div className="my-2">
        <hr className="text-body-secondary" />
      </div>

      <Row className="mt-3">
        <Col md={6}>
          <LinkContainer to="/change-password">
            <Button variant="primary">
              {<FontAwesomeIcon icon={faKey} fixedWidth className="me-1" />} Cambiar contraseña
            </Button>
          </LinkContainer>
        </Col>
      </Row>
    </div>
  );
}
