import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';
import { Badge, Button, Collapse, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMagic,
  faRobot,
  faTrashAlt,
  faShareSquare,
  faWarning,
  faFileCircleExclamation,
  faBan,
} from '@fortawesome/free-solid-svg-icons';
import APIClient from '../services/APIClient';
import { OrderItem, PriceListDetail, Product } from '../types/model';
import { formatAmount } from '../views/Utils';
import { useAppContext } from '../context/AppContext';
import { OrderItemMatchByFields } from '../constants';

interface OrderItemTableRowProps {
  index: number;
  item: OrderItem;
  disabled: boolean;
  isAiDataVisible: boolean;
  onChange: (
    index: number,
    productId: number,
    quantity: number | null,
    unitId: number | null,
  ) => void;
  onDelete?: (index: number) => void;
  priceListDetails: PriceListDetail[] | null;
}

function OrderItemTableRow({
  index,
  item,
  disabled = false,
  isAiDataVisible,
  onChange,
  onDelete,
  priceListDetails,
}: OrderItemTableRowProps) {
  const [selectedDescription, setSelectedDescription] = useState({
    value: item.Product?.id ?? null,
    label: item.Product?.descripcion ?? '',
    isActive: !item.Product?.isEliminado,
    isInOrderPriceList: priceListDetails !== null ? priceListDetails.some((price) => price.productId === item.Product?.id) : true,
  });

  useEffect(() => {
    setSelectedDescription({
      value: item.Product?.id ?? null,
      label: item.Product?.descripcion ?? '',
      isActive: !item.Product?.isEliminado,
      isInOrderPriceList: priceListDetails !== null ? priceListDetails.some((price) => price.productId === item.Product?.id) : true,
    });
  }, [priceListDetails]);

  const [quantity, setQuantity] = useState<number | null>(item.cantidad);
  const [unitId, setUnitId] = useState<number | null>(item.unitId);
  const [results, setResults] = useState<any>([]);
  const [isFirstRun, setIsFirstRun] = useState<boolean>(true);
  const [isMinChars, setIsMinChars] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [unitsSelectionEnabled, setUnitsSelectionEnabled] = useState<boolean>(false);

  const { appState } = useAppContext();

  useEffect(() => {
    if (isFirstRun) {
      setIsFirstRun(false);
      return;
    }
    if (selectedDescription.value == null) {
      return;
    }
    onChange(index, +selectedDescription.value, quantity, unitId);
  }, [selectedDescription.value, quantity, unitId]);

  useEffect(() => {
    if (item?.cantidad === quantity) {
      return;
    }
    setQuantity(item?.cantidad);
  }, [item.cantidad]);

  useEffect(() => {
    // If there are no units or there is only one unit, don't enable units selection
    setUnitsSelectionEnabled(appState.units && appState.units.length > 1);
  }, [appState.units]);

  let text = 'Item interpretado por IA.';
  let color = 'green';

  if (item.aiData?.matchBy == 'none' && selectedDescription.value === null) {
    text = 'El texto interpretado no se encontró en la base de productos.';
    color = 'tomato';
  } else if (item.productId != item.aiData?.productId || item.cantidad != item.aiData?.quantity) {
    text = 'El item interpretado ha sido modificado manualmente.';
    color = 'cornflowerblue';
  }

  //#region Handle events ----------------------------------

  const handleShowProductButtonClick = () => {
    window.open(`${window.location.origin}/products/${item?.Product?.id}`, '_blank');
  };

  const handleOpenModalButtonClick = () => {
    setShowDeleteModal(true);
  };

  const handleCloseModalButtonClick = () => {
    setShowDeleteModal(false);
  };

  const loadOptionsHandler = (query: string, loader: any) => {
    // if the query is less than 3, return the default options
    if (query.length < 3) {
      setIsMinChars(false);
      loader([]);
      return;
    }

    setIsMinChars(true);
    // executes the promise and load the results or return []
    onLoadOptions(query)
      .then((optionsFormatted) => {
        loader(optionsFormatted);
      })
      .catch((error: any) => {
        loader([]);
      });
  };

  const onLoadOptions = async (query: string) => {
    const queryEncoded = encodeURIComponent(`%${query}%`);

    const productsData: Product[] = (await APIClient.get(`/products?freeText=${queryEncoded}`)).data
      .data;

    const optionsFormatted = productsData.map((product) => ({
      value: product.id,
      label: `${product.erpCodigo} | ${product.descripcion}`,
      isActive: !product.isEliminado,
      isInOrderPriceList: priceListDetails !== null ? priceListDetails.some((price) => price.productId === product.id) : true,
    }));

    setResults(optionsFormatted);
    return optionsFormatted;
  };

  const selectDescriptionChangeHandler = (option) => {
    const optionLabel = option.label.split(' | ')[1].trim();
    setSelectedDescription({
      value: option.value,
      label: optionLabel,
      isActive: option.isActive,
      isInOrderPriceList: option.isInOrderPriceList,
    });
  };

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue: number | null = parseInt(event.target.value);

    if (isNaN(inputValue)) {
      // if not numeric, assume null/empty
      inputValue = null;
    } else if (inputValue == 0) {
      // if zero, reject the value
      return;
    }
    setQuantity(inputValue);
  };

  const handleUnitChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let selectedUnitId: number | null = null;
    if (event.target.value) {
      selectedUnitId = parseInt(event.target.value);
    }

    setUnitId(selectedUnitId);
  };

  //#endregion Handle events -------------------------------

  const renderDeleteItemModal = () => (
    <Modal size="lg" show={showDeleteModal} onHide={handleCloseModalButtonClick}>
      <Modal.Header closeButton>
        <Modal.Title>Eliminar ítem</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>¿Está seguro de que desea eliminar éste ítem?</p>
        <p className="text-danger">
          {item.id !== null
            ? `${item?.Product?.descripcion ?? item?.aiData?.product} x ${quantity}u`
            : 'Item vacío.'}
        </p>
        <p className="text-muted">
          {item?.aiData?.matchBy && (
            <span>
              Item interpretado <strong>{item.aiData.product}</strong>
              {item?.Product?.id ? '.' : ', no se encontró en la base de datos.'}
            </span>
          )}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onDelete ? () => onDelete(index) : undefined}>
          Eliminar
        </Button>
        <Button variant="secondary" onClick={handleCloseModalButtonClick}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const renderUnitsDropdown = () => {
    if (!unitsSelectionEnabled) {
      return null;
    }

    return (
      <Form.Select
        value={unitId ? String(unitId) : undefined}
        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        onChange={handleUnitChange}
        disabled={disabled}
        aria-label="Unidad">
        <option>(selecciona)</option>
        {appState.units.map((unit) => (
          <option value={String(unit.id)} key={String(unit.id)}>
            {unit.name}
          </option>
        ))}
      </Form.Select>
    );
  };

  const formatProductSelectOptionLabel = (
    option: { label: string; isActive: boolean; isInOrderPriceList: boolean },
    { context },
  ) => {
    if (context === 'menu') {
      return (
        <div className="d-flex flex-column gap-2">
          <span>{option.label}</span>
          <div className="d-flex gap-1">
            {!option.isActive && (
              <Badge bg="danger" pill>
                <FontAwesomeIcon icon={faBan} className="me-1" />
                Inactivo
              </Badge>
            )}
            {!option.isInOrderPriceList && (
              <Badge bg="warning" pill>
                <FontAwesomeIcon icon={faFileCircleExclamation} className="me-1" />
                No está en la lista de precios
              </Badge>
            )}
          </div>
        </div>
      );
    }
    return (
      <div className="d-flex flex align-items-center gap-2">
        <span className="text-truncate w-100" style={{ maxWidth: 'calc(100% - 20px)' }}>
          {selectedDescription.label}
        </span>
        {!selectedDescription.isActive && (
          <div style={{ position: 'relative', zIndex: 100 }}>
            <OverlayTrigger placement="top" overlay={<Tooltip>Producto inactivo</Tooltip>}>
              <div style={{ display: 'inline-block' }}>
                <FontAwesomeIcon icon={faBan} className="me-1 text-danger" />
              </div>
            </OverlayTrigger>
          </div>
        )}
        {!selectedDescription.isInOrderPriceList && (
          <div style={{ position: 'relative', zIndex: 100 }}>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>No está en la lista de precios</Tooltip>}>
              <div style={{ display: 'inline-block' }}>
                <FontAwesomeIcon icon={faFileCircleExclamation} className="me-1 text-warning" />
              </div>
            </OverlayTrigger>
          </div>
        )}
      </div>
    );
  };

  const aiTooltip = <Tooltip>{text}</Tooltip>;
  return (
    <>
      {renderDeleteItemModal()}
      <tr style={{ height: '55px' }}>
        {/* Index / row number */}
        <td>
          <div className="d-flex align-items-center" style={{ height: '38px' }}>
            <span>{index + 1}</span>
          </div>
        </td>
        {/* CODE */}
        <td>
          <div className="d-flex flex-nowrap align-items-center" style={{ height: '38px' }}>
            <div className={item.aiData === undefined ? 'invisible' : ''}>
              <OverlayTrigger overlay={aiTooltip}>
                <div className="me-2">
                  <FontAwesomeIcon icon={faMagic} color={color} fixedWidth />
                </div>
              </OverlayTrigger>
            </div>
            <span>{item?.Product?.erpCodigo ?? ''}</span>
          </div>
          {item.aiData?.productCode && (
            <div className={item.aiData === undefined ? 'invisible' : ''}>
              <Collapse in={isAiDataVisible}>
                <div className="flex-vertical-center-start mt-1 text-muted fw-light">
                  <FontAwesomeIcon icon={faRobot} fixedWidth className="me-1" />
                  {item.aiData.productCode}
                  {item.aiData?.matchBy == OrderItemMatchByFields.code &&
                    ` (${item.aiData.matchPrecision * 100}%)`}
                </div>
              </Collapse>
            </div>
          )}
        </td>
        {/* DESCRIPTION */}
        <td>
          <div className="d-flex justify-content-start">
            <AsyncSelect
              menuPortalTarget={document.body}
              placeholder="Busque un producto..."
              loadingMessage={() => 'Buscando productos...'}
              noOptionsMessage={() => {
                return isMinChars ? 'Sin resultados' : 'Escriba por lo menos 3 caracteres';
              }}
              cacheOptions={false}
              isSearchable={true}
              isDisabled={disabled}
              loadOptions={debounce(loadOptionsHandler, 400)}
              defaultOptions={results}
              value={selectedDescription.value !== null ? selectedDescription : null}
              onChange={selectDescriptionChangeHandler}
              onBlur={() => setIsMinChars(false)}
              formatOptionLabel={formatProductSelectOptionLabel}
              styles={{
                container: (baseStyles) => ({
                  ...baseStyles,
                  width: '92%',
                  paddingRight: 'unset',
                  fontSize: '12px',
                }),
                singleValue: (baseStyles, state) => ({
                  ...baseStyles,
                  color: state.isDisabled ? 'black !important' : 'inherit',
                }),
              }}
            />
            {item?.Product?.id && (
              <Button
                variant=""
                className="hoverIcon hoverIconBlue"
                style={{ width: '24px' }}
                onClick={handleShowProductButtonClick}>
                <FontAwesomeIcon icon={faShareSquare} title="Ver producto" />
              </Button>
            )}
          </div>
          {item.aiData?.product && (
            <div className={item.aiData === undefined ? 'invisible' : ''}>
              <Collapse in={isAiDataVisible}>
                <div className="flex-vertical-center-start mt-1 text-muted fw-light">
                  <FontAwesomeIcon icon={faRobot} fixedWidth className="me-1" />
                  {item.aiData.product}
                  {(item.aiData?.matchBy == OrderItemMatchByFields.description ||
                    item.aiData?.matchBy == OrderItemMatchByFields.clientTag) &&
                    ` (${item.aiData.matchPrecision * 100}%)`}
                </div>
              </Collapse>
            </div>
          )}
        </td>
        {/* QUANTITY */}
        <td>
          <div className="d-flex flex-row">
            <div>
              <div className="d-flex justify-content-start align-items-center gap-1">
                <input
                  className={`text-end border border-secondary-subtle w-100 ${
                    unitsSelectionEnabled ? 'rounded-start' : 'rounded'
                  }`}
                  style={{ width: '30%', height: '38px' }}
                  type="text"
                  value={quantity ?? ''}
                  disabled={disabled}
                  required
                  onChange={handleQuantityChange}
                />
              </div>
              {item.aiData?.matchBy && (
                <div className={item.aiData === undefined ? 'invisible' : ''}>
                  <Collapse in={isAiDataVisible}>
                    <div className="flex-vertical-center-start mt-1 text-muted fw-light">
                      <FontAwesomeIcon icon={faRobot} fixedWidth className="me-1" />
                      {item.aiData.quantity}
                    </div>
                  </Collapse>
                </div>
              )}
            </div>
            <div>
              <div>{renderUnitsDropdown()}</div>
              {item.aiData?.matchBy && (
                <div className={item.aiData === undefined ? 'invisible' : ''}>
                  <Collapse in={isAiDataVisible}>
                    <div className="flex-vertical-center-start mt-1 text-muted fw-light">
                      <FontAwesomeIcon icon={faRobot} fixedWidth className="me-1" />
                      {item.aiData.unit}
                    </div>
                  </Collapse>
                </div>
              )}
            </div>
          </div>
        </td>
        {/* DELIVERED */}
        <td>
          <span style={{ display: 'block', height: '38px', lineHeight: '38px', textAlign: 'end' }}>
            {item?.entregado}
          </span>
        </td>
        {/* PRICE */}
        <td className="text-end v-middle">
          <span style={{ display: 'block', height: '38px', lineHeight: '38px' }}>
            {formatAmount(item?.precio ?? 0.0)}
            {item.originalPrice && item.originalPrice != item.precio && (
              <>
                <FontAwesomeIcon
                  icon={faWarning}
                  title={`Precio original indicado: ${formatAmount(item.originalPrice)}`}
                  className="ms-1"
                />
              </>
            )}
          </span>
        </td>
        {/* TOTAL */}
        <td className="text-end">
          <span style={{ display: 'block', height: '38px', lineHeight: '38px' }}>
            {quantity ? formatAmount(quantity * (item?.precio ?? 0.0)) : '-'}
          </span>
        </td>
        {/* ACTIONS */}
        <td className="text-end v-middle">
          {!disabled && (
            <Button
              variant=""
              className="hoverIcon hoverIconRed"
              onClick={handleOpenModalButtonClick}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          )}
        </td>
      </tr>
    </>
  );
}

export default OrderItemTableRow;
