import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, ButtonToolbar, Col, InputGroup, Modal, Form } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { useToasts } from 'react-toast-notifications';
import { DataTable } from '../../components';
import APIClient from '../../services/APIClient';
import { isObjectEmpty } from '../../utils';
import Utils from '../Utils';
import UIUtils from '../UIUtils';
import { DataTableColumnProps } from '../../types/dataTable';
import moment from 'moment';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { PriceList, PriceListDetail, Product, ProductCategory2 } from '../../types/model';
import { SelectRowProps } from '../../components/DataTable';

type PriceListDetailForEdit = PriceListDetail & {
  porcentaje?: number;
  precioFinal?: number;
  isSelected?: boolean;
};

export default function ListaPrecioList() {
  useDocumentTitle('Listas de Precio');
  const { addToast } = useToasts();

  const [allSelected, setAllSelected] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isPriceDataLoading, setIsPriceDataLoading] = useState(true);
  const [isSavingPrices, setIsSavingPrices] = useState(false);
  const [productCategories2, setProductCategories2] = useState<ProductCategory2[]>([]);
  const [listaPrecioDetalle, setListaPrecioDetalle] = useState<PriceListDetailForEdit[]>([]);
  const [listasPrecio, setListasPrecio] = useState<PriceList[]>([]);
  const [modificador, setModificador] = useState(0);
  const [selectedLista, setSelectedLista] = useState<PriceList>();
  const [showPriceModal, setShowPriceModal] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [products, setProducts] = useState<Product[]>();

  // let tableRef;

  useEffect(() => {
    loadListasPrecio();
  }, []);

  /**
   * update state prices using modificador after clicking "Aplicar" button
   */
  const applyModificador = () => {
    const newDetalles = [...listaPrecioDetalle];
    let updatedModificador = modificador / 100 + 1;
    if (allSelected) {
      newDetalles.forEach((det) => {
        det.porcentaje = modificador; // parseInt(modificador, 10);
        det.precioFinal = Math.round(det.precio * updatedModificador * 100) / 100;
      });
    } else {
      const selectedDetalles = newDetalles.filter((det) => det.isSelected);
      selectedDetalles.forEach((det) => {
        det.precioFinal = Math.round(det.precio * updatedModificador * 100) / 100;
        det.porcentaje = Math.round(modificador * 100) / 100;
        const index = newDetalles.findIndex((newDet) => newDet.id === det.id);
        newDetalles.splice(index, 1, det);
      });
    }
    setListaPrecioDetalle(newDetalles);
    setIsPriceDataLoading(false);
  };

  const showModalAndGetLista = async (row) => {
    if (!isObjectEmpty(selectedLista)) {
      try {
        const getListResponse = await APIClient.get(`/listas-precio/${row.id}`);
        const productCategories2 = await APIClient.get('/product-categories-2');
        const newSelectedLista = getListResponse.data.data;
        const detalles = [...newSelectedLista.detalles];
        detalles.forEach((det) => {
          det.isSelected = false;
          det.porcentaje = 0;
          det.precioFinal = det.precio;
        });
        setListaPrecioDetalle(detalles);
        setSelectedLista(newSelectedLista);
        setShowPriceModal(true);
        setProductCategories2(productCategories2.data.data);
      } catch (error) {
        addToast(`Ocurrió un error: "${(error as Error).message}"`, {
          appearance: 'error',
        });
      } finally {
        setIsPriceDataLoading(false);
      }
    }
  };

  // const onTableRef = (props) => {
  //   tableRef = props;
  // };

  /**
   * @param  {string} type - change type
   * @param  {number} page - page number
   * @param  {number} sizePerPage - current page size
   * receives table changes, makes custom API calls and saves results to state.
   */
  const onTableUpdate = async (queryParameters) => {
    const { freeText, pagination, sorting } = queryParameters;
    try {
      const { direction } = sorting;
      let { field } = sorting;
      if (field && field === 'productCategory2') {
        field = field.concat('_id');
      }
      if (field && field === 'marca') {
        field = field.concat('Codigo');
      }
      const productsRes = await APIClient.get(
        `/products?limit=${pagination.limit}&offset=${pagination.offset}&freeText=${
          freeText && `%${freeText}%`
        }&sortField=${field || ''}&sortDir=${direction || ''}`,
      );
      const products = productsRes.data.data;
      setProducts(products);
      setTotalSize(productsRes.data.meta.total);
    } catch (error) {
      addToast(`Ocurrió un error: "${(error as Error).message}"`, {
        appearance: 'error',
      });
    }
  };

  const handleModalClose = () => {
    setShowPriceModal(false);
    setListaPrecioDetalle([]);
    setSelectedLista(undefined);
    setModificador(0);
  };

  const renderChangePricesModal = () => {
    const columns = [
      {
        dataField: 'product.descripcion',
        text: 'Producto',
        sort: true,
        editable: false,
      },
      {
        dataField: 'product.productCategory2.description',
        text: 'Categoría 2',
        sort: true,
        editable: false,
      },
      {
        dataField: 'precio',
        text: 'Precio',
        sort: true,
        editable: false,
        classes: 'text-end',
        formatter: (cellContent, row) => {
          return cellContent ? (
            Utils.formatAmount(cellContent)
          ) : (
            <span className="text-danger font-weight-bold">No tiene precio.</span>
          );
        },
      },
      {
        dataField: 'porcentaje',
        text: 'Porcentaje',
        sort: true,
        editable: false,
        classes: 'text-end',
        formatter: (cellContent, row) => `${cellContent.toString()}%`,
      },
      {
        dataField: 'precioFinal',
        text: 'Precio Final',
        sort: true,
        editable: false,
        classes: 'text-end',
        formatter: (cellContent, row) => Utils.formatAmount(cellContent),
      },
    ];

    const selectRowProps: SelectRowProps = {
      mode: 'checkbox',
      clickToEdit: false,
      clickToSelect: true,
      onSelect: onSelectDetalle,
      onSelectAll: (isSelect, rows) => {
        // const { searchText } = tableRef.searchContext.props;
        const searchText = ''; // TODO: check this

        if (isSelect) {
          if (searchText.length > 0) {
            const productCategory2Ids = productCategories2
              .filter((productCategory2) =>
                productCategory2.description.toLowerCase().match(searchText.toLowerCase()),
              )
              .map((l) => l.id);
            const filteredDetalles: number[] = [];
            listaPrecioDetalle.forEach((det) => {
              if (
                productCategory2Ids.some(
                  (productCategory2) => productCategory2 === det.Product.category2Id,
                ) ||
                (det.Product.descripcion &&
                  det.Product.descripcion.toLowerCase().match(searchText.toLowerCase()))
              ) {
                det.isSelected = true;
                filteredDetalles.push(det.id);
              }
            });
            return filteredDetalles;
          }
          setAllSelected(true);
          return listaPrecioDetalle.map((r) => r.id);
        }
        const newUpdatedDetalles = listaPrecioDetalle.map((det) => {
          det.isSelected = false;
          return det;
        });
        setAllSelected(false);
        setListaPrecioDetalle(newUpdatedDetalles);
        return [];
      },
    };

    return (
      <Modal size="xl" show={showPriceModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          {selectedLista ? (
            <Modal.Title>{selectedLista.descripcion}</Modal.Title>
          ) : (
            <Modal.Title>Lista de Precio</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <Col md={6}>
            <Form.Group controlId="modificador">
              <Form.Label>Modificar en: </Form.Label>
              <InputGroup>
                <InputGroup.Text>%</InputGroup.Text>
                <Form.Control
                  as="input"
                  name="modificador"
                  type="number"
                  onChange={onModificadorChange}
                  value={modificador}
                  min={-100}
                  max={1000}
                />
                <Button onClick={applyModificador} className="ms-3" size="sm" variant="primary">
                  Aplicar
                </Button>
              </InputGroup>
            </Form.Group>
          </Col>
          {selectedLista ? (
            <DataTable
              isDataLoading={isPriceDataLoading}
              columns={columns}
              data={listaPrecioDetalle}
              keyField="id"
              selectRow={selectRowProps}
              // onTableRef={onTableRef}
              onTableUpdate={onTableUpdate}
              // cellEdit={cellEditFactory({
              //   mode: 'click',
              //   blurToSave: true,
              //   autoSelectText: true,
              //   afterSaveCell: this.afterSaveSelectedDetalle,
              // })}
            />
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose} disabled={isSavingPrices}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={onSavePrecios} disabled={isSavingPrices}>
            Guardar Cambios
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const onLoadForm = async () => {
    const productCategories2 = await APIClient.get('/product-categories-2');
    setProductCategories2(productCategories2.data.data);
  };

  const onModificadorChange = (e) => {
    setModificador(e.target.value);
  };

  const onSavePrecios = async () => {
    setIsSavingPrices(true);

    const updatedDetalles = listaPrecioDetalle.filter((det) => det.porcentaje !== 0);
    const detallesToUpdate = updatedDetalles.map((det) => ({
      id: det.id,
      porcentaje: det.porcentaje,
    }));
    try {
      if (selectedLista) {
        await APIClient.patch(
          `/listas-precio/${selectedLista.id}/actualizar-precios`,
          detallesToUpdate,
        );
        addToast(`Lista de Precios ${selectedLista.id} guardada con éxito`, {
          appearance: 'success',
          autoDismiss: true,
        });
        setListaPrecioDetalle([]);
        setSelectedLista(undefined);
        setModificador(0);
      } else {
        console.warn('No list selected');
      }
    } catch (error) {
      addToast(`Ocurrió un error al guardar los cambios: ${error}`, {
        appearance: 'error',
      });
    } finally {
      setShowPriceModal(false);
      setIsSavingPrices(false);
    }
  };

  const onSelectDetalle = (row, isSelect) => {
    // const { selectedLista } = this.state;
    setListaPrecioDetalle(
      listaPrecioDetalle.map((lpd) => (lpd.id === row.id ? { ...lpd, isSelected: isSelect } : lpd)),
    );
  };

  const onRetrieveEntity = async () => {
    if (selectedLista && !isObjectEmpty(selectedLista)) {
      try {
        const newSelectedListaResponse = await APIClient.get<PriceList>(
          `/listas-precio/${selectedLista.id}`,
        );
        const newSelectedLista = newSelectedListaResponse.data.data;
        const detalles: PriceListDetailForEdit[] = [...newSelectedLista.detalles];
        detalles.forEach((det) => {
          det.isSelected = false;
        });
        setListaPrecioDetalle(detalles);
        setSelectedLista(newSelectedLista);
      } catch (error) {
        addToast(`Ocurrió un error: "${(error as Error).message}"`, {
          appearance: 'error',
        });
      } finally {
        setIsDataLoading(false);
      }
    }
  };

  async function loadListasPrecio() {
    try {
      const listasRes = await APIClient.get<PriceList[]>('/listas-precio');
      setListasPrecio(listasRes.data.data);
    } catch (error) {
      console.error('Error loading price list', error);
      addToast(`Ocurrió un error: "${(error as Error).message}"`, {
        appearance: 'error',
      });
    } finally {
      setIsDataLoading(false);
    }
  }

  const columns: DataTableColumnProps[] = [
    {
      dataField: 'erpCode',
      text: 'Código',
      sort: true,
      classes: 'font-monospace',
    },
    {
      dataField: 'descripcion',
      text: 'Descripción',
      sort: true,
    },
    {
      dataField: 'validitySince',
      text: 'Validez',
      sort: true,
      formatter: (cellContent, row: PriceList) => {
        if (!row.validitySince && !row.validityUntil) {
          return null;
        }

        const dateValues: string[] = [];
        if (row.validitySince) {
          dateValues.push(moment(row.validitySince).format('DD/MM/YYYY'));
        }
        if (row.validityUntil) {
          dateValues.push(moment(row.validityUntil).format('DD/MM/YYYY'));
        }
        return dateValues.join(' - ');
      },
    },
    {
      dataField: 'tipo',
      text: 'Tipo',
      sort: true,
    },
    {
      dataField: 'eliminadoFlag',
      text: 'Estado',
      sort: true,
      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => {
        if (cellContent === null) {
          return '';
        }
        cellContent =
          typeof cellContent !== 'boolean' ? Utils.stringToBoolean(cellContent) : cellContent;
        const UISettings = {
          text: { true: 'Inactivo', false: 'Activo' },
          color: { true: 'danger', false: 'success' },
        };
        return UIUtils.getStatusBadge(cellContent, UISettings);
      },
    },
    {
      dataField: 'actions',
      isDummyField: true,
      text: '',
      formatter: (cellContent, row) => (
        <ButtonToolbar className="justify-content-center">
          <ButtonGroup>
            <LinkContainer to={`/listas-precio/${row.id}`}>
              <Button size="sm" variant="outline-primary" title="Editar">
                <FontAwesomeIcon icon={faEye} fixedWidth size="xs" />
              </Button>
            </LinkContainer>
            {/* <Button
                size="sm"
                variant="outline-primary"
                onClick={() => this.showModalAndGetLista(row)}
                title="Modificar Precios"
              >
                <FontAwesomeIcon icon={faDollarSign} fixedWidth size="xs" />
              </Button> */}
          </ButtonGroup>
        </ButtonToolbar>
      ),
    },
  ];

  /** Columns for exporting to excel */
  const exportColumns = [
    {
      fieldName: 'id',
      title: 'ID',
    },
    {
      fieldName: 'tipo',
      title: 'Tipo',
    },
    {
      fieldName: 'codigo',
      title: 'Código',
    },
    {
      fieldName: 'descripcion',
      title: 'Descripción',
    },
    {
      fieldName: 'eliminadoFlag',
      title: 'Inactivo',
      parseBoolean: true,
    },
  ];

  return (
    <div>
      {renderChangePricesModal()}
      <h1 className="page-title">Listas de Precio</h1>
      <DataTable
        isDataLoading={isDataLoading}
        columns={columns}
        data={listasPrecio}
        keyField="id"
        // addButton="/listas-precio/nuevo"
        exportConfig={{
          exportURL: '/listas-precio/export.xlsx',
          columns: exportColumns,
        }}
      />
    </div>
  );
}
