import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'
import { DataTableExportCSVButtonProps } from '../types/dataTable'
import { exportEntityRecords } from './utils'

function DataTableExportSelectedButton({
  onGetExportData,
  propsTP,
  exportCustomHeaders,
  exportExtraFields,
  exportFileName,
  exportConfig,
  exportCols,
  selectedRowIds,
}: DataTableExportCSVButtonProps) {
  const { addToast } = useToasts();

  const disabled = !selectedRowIds || selectedRowIds.length === 0;

  const handleClick = async () => {
    await exportEntityRecords({
      exportConfig,
      selectedRowIds,
      exportSelectedOnly: true,
      onGetExportData,
      propsTP,
      exportCols,
      exportExtraFields,
      exportCustomHeaders,
      exportFileName,
      addToast,
    });
  };

  return (
    <Button
      size="sm"
      variant="outline-primary"
      title="Exportar items seleccionados"
      onClick={handleClick}
      disabled={disabled}>
      <FontAwesomeIcon icon={faFileExcel} fixedWidth size="xs" />
    </Button>
  );
}

export default DataTableExportSelectedButton;
