import React from 'react';
import { Badge } from 'react-bootstrap';
import { SyncState, StatusCode } from '../constants';

export interface SettingsStatusBadge {
  color: {
    true: string;
    false: string;
  };
  text: {
    true: string;
    false: string;
  };
}

export interface Pagination {
  limit: number;
  offset: number;
  page?: number;
}

export interface Sorting {
  direction?: string;
  field?: string;
}
export interface QueryParameters {
  freeText?: string;
  pagination: Pagination;
  sorting: Sorting;
}

export function buildListEndpointQueryParams(queryParameters?: QueryParameters) {
  if (!queryParameters) {
    return '';
  }

  const params = new URLSearchParams();
  if (queryParameters.pagination) {
    params.append('limit', String(queryParameters.pagination.limit));
    params.append('offset', String(queryParameters.pagination.offset));
  }
  if (
    queryParameters.sorting &&
    queryParameters.sorting.field &&
    queryParameters.sorting.direction
  ) {
    params.append('sortField', queryParameters.sorting.field);
    params.append('direction', queryParameters.sorting.direction);
  }

  return params.toString();
}

/**
 * Returns the name of a status given the internal status code
 * @param {string} internalStatus Internal status code
 * @param {string} erpStatusCode ERP status code (optional)
 */
function getSolicitudEstadoBadge(internalStatus: string, erpStatusCode?: string | null) {
  let label = erpStatusCode ?? '-';
  let variant = 'secondary';

  switch (internalStatus) {
    case StatusCode.nonDelivered:
      label = erpStatusCode ?? 'No Entregado';
      variant = 'secondary';
      break;
    case StatusCode.draft:
      label = erpStatusCode ?? 'Borrador';
      variant = 'dark';
      break;
    case StatusCode.pending:
      label = erpStatusCode ?? 'Pendiente verificar';
      variant = 'warning';
      break;
    case StatusCode.cancelled:
      label = erpStatusCode ?? 'Cancelado';
      variant = 'danger';
      break;
    case StatusCode.delivered:
      label = erpStatusCode ?? 'Entregado';
      variant = 'secondary';
    case StatusCode.confirmed:
      label = erpStatusCode ?? 'Verificado';
      variant = 'success';
      break;
    case StatusCode.deliveredPartial:
      label = erpStatusCode ?? 'Entregado Parcial';
      variant = 'info';
      break;
    case StatusCode.inPreparation:
      label = erpStatusCode ?? 'En Preparación';
      variant = 'primary';
      break;
    case StatusCode.invoiced:
      label = erpStatusCode ?? 'Facturado';
      variant = 'primary';
      break;
    default:
      break;
  }

  return <Badge bg={variant}>{label}</Badge>;
}

function getEntregaEstadoBadge(code: string): JSX.Element {
  let nombre = '-';
  let variant = 'secondary';

  switch (code) {
    case 'EA':
      nombre = 'En Carga';
      variant = 'light';
      break;
    case 'EC':
      nombre = 'Confirmada';
      variant = 'success';
      break;
    case 'EF':
      nombre = 'Facturada';
      variant = 'info';
      break;
    case 'PC':
      nombre = 'Pendiente';
      variant = 'warning';
      break;
    default:
      break;
  }

  return <span className={`badge badge-${variant}`}>{nombre}</span>;
}

function getSyncStateBadge(codigo) {
  let nombre = '-';
  let variant = 'secondary';
  switch (codigo) {
    case SyncState.InProcess:
      nombre = 'En proceso';
      variant = 'info';
      break;
    case SyncState.Success:
      nombre = 'Sincronizado';
      variant = 'success';
      break;
    case SyncState.Error:
      nombre = 'Error';
      variant = 'danger';
      break;
    case SyncState.PartialSuccess:
      nombre = 'Sincronizado con errores';
      variant = 'warning';
      break;
    default:
      break;
  }
  return <span className={`badge badge-${variant}`}>{nombre}</span>;
}

function getStatusBadge(fieldStatus: boolean, settingsObject: SettingsStatusBadge): JSX.Element {
  const statusKey = fieldStatus ? 'true' : 'false';

  return (
    <span className={`badge bg-${settingsObject.color[statusKey]}`}>
      {settingsObject.text[statusKey]}
    </span>
  );
}

function getClienteEstadoBadge(codigo) {
  let nombre = '-';
  let variant = 'secondary';
  switch (codigo) {
    case 'Activo':
      nombre = codigo;
      variant = 'success';
      break;
    case 'Inactivo':
      nombre = codigo;
      variant = 'danger';
      break;
    case 'Prospecto':
      nombre = codigo;
      variant = 'warning';
      break;
    default:
      break;
  }
  return <span className={`badge bg-${variant}`}>{nombre}</span>;
}

function generateHours() {
  const newHours: { hour: string }[] = [];
  for (let i = 0; i < 24; i += 1) {
    let hour = i.toString();
    if (i < 10) {
      hour = hour.padStart(2, '0');
    }
    newHours.push({ hour: `${hour}:00` });
    newHours.push({ hour: `${hour}:30` });
  }
  return newHours;
}

function generateRandomColors(n) {
  const randomColors: string[] = [];
  for (let i = 0; i < n; i += 1) {
    let randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    if (randomColor.length < 7) {
      const newColor = generateRandomColors(1)[0];
      randomColor = newColor;
    }
    randomColors.push(randomColor);
  }
  return randomColors;
}
const bgBlueOnMouseEnter = {
  onMouseEnter: (e) => {
    e.target.style.cursor = `pointer`;
    // if (e.target.tagName === 'TD') {
    //   e.target.classList.add('bg-primary');
    //   e.target.style.color = 'white';
    // }
    // if (e.target.tagName === 'DIV') {
    //   e.target.parentNode.classList.add('bg-primary');
    //   e.target.parentNode.style.color = 'white';
    // }
    // if (e.target.tagName === 'SMALL') {
    //   e.target.parentNode.parentNode.classList.add('bg-primary');
    //   e.target.parentNode.parentNode.style.color = 'white';
    // }
    // if (e.target.tagName === 'A') {
    //   e.target.parentNode.parentNode.classList.add('bg-primary');
    // }
  },
  onMouseLeave: (e) => {
    // if (e.target.tagName === 'TD') {
    //   e.target.classList.remove('bg-primary');
    //   e.target.style.color = 'black';
    // }
    // if (e.target.tagName === 'DIV') {
    //   e.target.parentNode.classList.remove('bg-primary');
    //   e.target.parentNode.style.color = 'black';
    // }
    // if (e.target.tagName === 'SMALL') {
    //   e.target.parentNode.parentNode.classList.remove('bg-primary');
    //   e.target.parentNode.parentNode.style.color = 'black';
    // }
    // if (e.target.tagName === 'A') {
    //   e.target.parentNode.parentNode.classList.remove('bg-primary');
    //   e.target.style.color = '#007BFF';
    // }
  },
};

function getBase64FromBlob(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      let base64String = fileReader.result as string;
      base64String = base64String.substring(base64String.indexOf(',') + 1);
      resolve(base64String);
    };
    fileReader.onerror = (ev) => {
      console.error('Error reading Blob', ev);
      reject(new Error('Error reading Blob'));
    };

    fileReader.readAsDataURL(blob);
  });
}

export default {
  getSolicitudEstadoBadge,
  generateHours,
  generateRandomColors,
  getEntregaEstadoBadge,
  getSyncStateBadge,
  getClienteEstadoBadge,
  bgBlueOnMouseEnter,
  getStatusBadge,
  getBase64FromBlob,
};
