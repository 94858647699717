import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Moment from 'react-moment';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Badge, Button, Card, Col, Row } from 'react-bootstrap';
import { faCirclePlay, faCircleStop } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faClock, faHandPointer, faUndo } from '@fortawesome/free-solid-svg-icons';

import {
  Connector,
  ConnectorExecution,
  ConnectorExecutionStatus,
  ConnectorExecutionTrigger,
  ConnectorSummary,
  ConnExecResult,
} from '../../types/model';
import { buildListEndpointQueryParams, QueryParameters } from '../UIUtils';
import apiClient from '../../services/APIClient';
import { DataTable, Loading } from '../../components';
import { DataTableColumnProps } from '../../types/dataTable';
import useDocumentTitle from '../../hooks/useDocumentTitle';

export default function ConnectorView() {
  useDocumentTitle('Integraciones');
  const { code } = useParams();
  const { addToast } = useToasts();

  const [connector, setConnector] = useState<ConnectorSummary>();
  const [executions, setExecutions] = useState<ConnectorExecution[]>([]);
  const [executionsTotalCount, setExecutionsTotalCount] = useState(0);
  const [isConnectorLoading, setIsConnectorLoading] = useState(true);
  const [areExecutionsLoading, setAreExecutionsLoading] = useState(true);

  async function loadConnector() {
    try {
      setIsConnectorLoading(true);
      // load the connector
      const connectorResponse = await apiClient.get<Connector>(`/connectors/${code}`);

      setConnector(connectorResponse.data.data);
    } catch (error) {
      console.error('Error in loading Connectors log', error);
      addToast(
        `Ocurrió un error cargando la información de la integración. ${(error as Error).message}`,
        {
          appearance: 'error',
        },
      );
    } finally {
      setIsConnectorLoading(false);
    }
  }

  async function loadExecutions(queryParameters?: QueryParameters) {
    setAreExecutionsLoading(true);
    try {
      const queryParams = buildListEndpointQueryParams(queryParameters);

      // load the connector executions
      const executionsResponse = await apiClient.get<ConnectorExecution[]>(
        `/connectors/${code}/executions?${queryParams}`,
      );
      setExecutions(executionsResponse.data.data);
      setExecutionsTotalCount(executionsResponse.data.meta.total);
    } catch (error) {
      console.error('Error in loading Connectors log', error);
      addToast(
        `Ocurrió un error cargando la información de la integración. ${(error as Error).message}`,
        {
          appearance: 'error',
        },
      );
    } finally {
      setAreExecutionsLoading(false);
    }
  }

  useEffect(() => {
    if (code) {
      loadConnector();
    }
  }, [code]);

  function handleTableUpdate(queryParameters: QueryParameters) {
    return loadExecutions(queryParameters);
  }

  function ConnectorExecutionStatus({ status }: { status: ConnectorExecutionStatus }) {
    switch (status) {
      case 'pending':
        return <Badge bg="info">Pendiente</Badge>;
      case 'running':
        return <Badge bg="warning">En ejecución</Badge>;
      case 'finished':
        return <Badge bg="success">Finalizado</Badge>;
      case 'error':
        return <Badge bg="danger">Error</Badge>;
      default:
        return <Badge bg="secondary">{status}</Badge>;
    }
  }

  // if no connector, display loader
  if (!connector || isConnectorLoading) {
    return <Loading />;
  }

  function ConnectorExecutionTrigger({ trigger }: { trigger: ConnectorExecutionTrigger }) {
    switch (trigger) {
      case 'cron':
        return (
          <span>
            <FontAwesomeIcon icon={faClock} fixedWidth className="me-1" /> Programado
          </span>
        );
      case 'on_demand':
        return (
          <span>
            <FontAwesomeIcon icon={faHandPointer} fixedWidth className="me-1" />A demanda
          </span>
        );
      default:
        return <span>{trigger}</span>;
    }
  }

  const tableColumns: DataTableColumnProps[] = [
    {
      dataField: 'createdAt',
      text: 'Fecha',
      formatter: (cellContent, row) => cellContent && <Moment interval={0}>{cellContent}</Moment>,
    },
    {
      dataField: 'status',
      text: 'Estado',
      formatter: (cellContent, row) =>
        cellContent && <ConnectorExecutionStatus status={cellContent} />,
    },
    {
      dataField: 'duration',
      isDummyField: true,
      text: 'Duración',
      formatter: (cellContent, row: ConnectorExecution) => {
        return row.finishedAt ? (
          <span>{moment(row.createdAt).from(row.finishedAt, true)}</span>
        ) : (
          '-'
        );
      },
    },
    {
      dataField: 'trigger',
      text: 'Disparador',

      formatter: (cellContent, row) =>
        cellContent && <ConnectorExecutionTrigger trigger={cellContent} />,
    },
    {
      dataField: 'itemsSucceeded',
      text: 'Items exitosos',
      classes: function (cellContent, row, rowIndex, colIndex) {
        return `text-end ${cellContent > 0 ? 'bg-success-subtle' : ''}`;
      },
    },
    {
      dataField: 'itemsFailed',
      text: 'Items fallidos',
      classes: function callback(cellContent, row, rowIndex, colIndex) {
        return `text-end ${cellContent > 0 ? 'bg-danger-subtle' : ''}`;
      },
    },
    {
      dataField: 'result',
      text: '',
      align: 'center',
      formatter: (cellContent: ConnectorExecution['result']) => {
        if (!cellContent) {
          return null;
        }

        try {
          const parsedResult = JSON.parse(cellContent) as ConnExecResult;

          const contentLinesToDisplay: string[] = [];
          if (parsedResult.message) {
            contentLinesToDisplay.push(parsedResult.message);
          }
          if (parsedResult.items) {
            for (const key in parsedResult.items) {
              const item = parsedResult.items[key];
              const success = item.success ?? 0;
              const skip = item.skip ?? 0;
              const fail = item.fail ?? 0;
              contentLinesToDisplay.push(`${key}: ${success} (${skip}) / ${success + skip + fail}`);
            }
          }

          return (
            <span title={contentLinesToDisplay.join('\n')}>
              <FontAwesomeIcon icon={faCircleInfo} />
            </span>
          );
        } catch (error) {
          console.error('Error parsing execution result', error);
          return null;
        }
      },
    },
  ];

  return (
    <div>
      <div className="page-title d-flex flex-row justify-content-between align-items-center">
        <h1>{connector.name}</h1>
        <div className="float-end">
          {connector.isEnabled ? (
            <Badge pill bg="success">
              Activo
            </Badge>
          ) : (
            <Badge pill bg="warning">
              Inactivo
            </Badge>
          )}
        </div>
      </div>

      <Row className="mb-3">
        {connector.connectorLinks?.length &&
          connector.connectorLinks.map((link, index) => (
            <Col md={6} key={link.id}>
              <Card>
                <Card.Body>
                  <h4 className="fs-5">
                    {link.isEnabled ? (
                      <FontAwesomeIcon
                        icon={faCirclePlay}
                        fixedWidth
                        title="Activo"
                        className="text-success me-1"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faCircleStop}
                        fixedWidth
                        title="Inactivo"
                        className="text-danger me-1"
                      />
                    )}
                    {link.name ?? `Cuenta #${index + 1}`}
                  </h4>
                  <div>
                    <label className="me-2">Próxima sync:</label>

                    {link.nextExecutionAt ? (
                      <span title={moment(link.nextExecutionAt).toString()}>
                        {moment(link.nextExecutionAt).toNow(true)}
                      </span>
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
      </Row>

      <DataTable
        remote={{
          filter: true,
          pagination: true,
          sort: false,
          cellEdit: false,
        }}
        showSearch={false}
        columns={tableColumns}
        data={executions}
        onTableUpdate={handleTableUpdate}
        isDataLoading={areExecutionsLoading}
        totalSize={executionsTotalCount}
        keyField="id"
        defaultSorted={[{ dataField: 'createdAt', order: 'desc' }]}
      />

      <Button variant="secondary" className="mt-3" onClick={() => window.history.back()}>
        <FontAwesomeIcon icon={faUndo} fixedWidth className="me-1" />
        Volver
      </Button>
    </div>
  );
}
