import React, { useEffect, useMemo, useState } from 'react';
import { saveAs } from 'file-saver';
import { File } from '../types/model';
import { getTypeFromMimeType, shouldOpenInBrowser } from './utils';
import apiClient from '../services/APIClient';
import config from '../config';
import Loading from './Loading';
import { DownloadFileType } from '../constants';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFileCsv, faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { useToasts } from 'react-toast-notifications';

interface FileDisplayProps {
  file: File;
}

export default function FileDisplay({ file }: FileDisplayProps) {
  const fileType = useMemo(() => getTypeFromMimeType(file.mimeType), [file.mimeType]);

  const [isLoading, setLoading] = useState<boolean>(true);
  const [loadContentStatus, setLoadContentStatus] = useState<'fulfilled' | 'rejected'>();
  const [fileContent, setFileContent] = useState<Blob | null>(null);
  const [cleanMimeType, setCleanMimeType] = useState<string>();
  const [audioRef, setAudioRef] = useState<React.RefObject<HTMLAudioElement> | null>(null);

  useEffect(() => {
    apiClient
      .getFile(`${config.api.url}/files/${file.id}`, { responseType: 'arraybuffer' })
      .then((response) => {
        const ref = React.createRef<HTMLAudioElement>();

        const mimeTypeClean = file.mimeType.split(';')[0];

        setLoadContentStatus('fulfilled');
        setCleanMimeType(mimeTypeClean);
        setFileContent(new Blob([response.data], { type: mimeTypeClean }));
        setAudioRef(ref);
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
        setLoadContentStatus('rejected');
        setFileContent(null);
        setAudioRef(null);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      setLoading(true);
    };
  }, []);

  useEffect(() => {
    if (audioRef && audioRef.current) {
      audioRef.current.load();
    }
  }, [isLoading]);
  const { addToast } = useToasts();

  function handleSaveButtonClick(e: any) {
    e.preventDefault();
    if (!fileContent) {
      addToast('El archivo no está disponible', { appearance: 'error' });
      return;
    }

    if (shouldOpenInBrowser(fileType)) {
      const fileUrl = URL.createObjectURL(fileContent);
      window.open(fileUrl, '_blank');
    } else {
      saveAs(
        fileContent,
        `adjunto_.${moment(new Date()).format('DD-MM-YYYY_HH:mm')}.${file.name.split('.').at(-1)}`,
      );
    }
  }

  function renderIconFromDownloadFileType(downloadFileType: DownloadFileType) {
    switch (downloadFileType) {
      case DownloadFileType.pdf:
        return <FontAwesomeIcon icon={faFilePdf} fixedWidth />;
      case DownloadFileType.csv:
        return <FontAwesomeIcon icon={faFileCsv} fixedWidth />;
      case DownloadFileType.spreadsheet:
        return <FontAwesomeIcon icon={faFileExcel} fixedWidth />;
      default:
        return <FontAwesomeIcon icon={faFile} fixedWidth />;
    }
  }

  return (
    <div className="file-display my-0 mx-2">
      {isLoading ? (
        <Loading />
      ) : loadContentStatus !== 'fulfilled' ? (
        <span>{`Error cargando recurso ${file.name.split('.').at(-1)}`}</span>
      ) : (
        <div>
          {fileType === DownloadFileType.audio ? (
            <div>
              <audio className="file-display-audio" ref={audioRef} controls={true}>
                <source src={URL.createObjectURL(fileContent!)} type={cleanMimeType} />
              </audio>
              <button
                style={{ display: 'none' }}
                type="button"
                onClick={(event) => audioRef && audioRef.current?.play()}>
                PLAY
              </button>
            </div>
          ) : fileType === DownloadFileType.image ? (
            <div className="file-display-image">
              <img
                className="source-order-img"
                alt="imagen de pedido"
                src={URL.createObjectURL(fileContent!)}
              />
              <button type="button" onClick={handleSaveButtonClick}>
                Abrir imagen
              </button>
            </div>
          ) : (
            <div className="d-flex align-items-center file-display-default">
              {renderIconFromDownloadFileType(fileType)}
              <button type="button" className="file" onClick={handleSaveButtonClick}>
                {`${
                  shouldOpenInBrowser(fileType) ? 'Abrir' : 'Descargar'
                } ${file.name.split('.').at(-1)} adjunto.`}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
