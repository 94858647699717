import { Navigate, Route, useParams } from 'react-router-dom'
import OrderAIEdit from './OrderAIEdit'
import PedidoList from './OrderList'

const PedidoRedirect = () => {
  // We need the id to redirect to the correct route
  const { id } = useParams();
  return <Navigate to={`/orders/${id}`} replace />;
};

const routes = () =>
  [
    // <Route path="/pedidos/nuevo" exact element={<PedidoEdit />} />,
    <Route path="/pedidos" element={<Navigate to="/orders" replace />} key="/pedidos" />,
    <Route path="/pedidos/:id" element={<PedidoRedirect />} key="/pedidos/:id" />,
    <Route path="/orders/:id" element={<OrderAIEdit />} key="/orders/:id" />,
    <Route path="/orders" element={<PedidoList />} key="/orders" />,
  ].map((route) => route);

export default routes;
