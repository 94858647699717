import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import APIClient from '../services/APIClient';
import { useToasts } from 'react-toast-notifications';

interface ReportAnomalyModalProps {
  show: boolean;
  onHide: () => void;
  orderId: number;
  orderName: string;
}

function ReportAnomalyModal({ show, onHide, orderId, orderName }: ReportAnomalyModalProps) {
  const [description, setDescription] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { addToast, removeToast } = useToasts();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    let loadingToastId: string | null = null;

    try {
      loadingToastId = addToast('Reportando anomalía...', {
        appearance: 'info',
        autoDismiss: false,
      });

      await APIClient.post(`/orders/${orderId}/report`, {
        description,
      });

      addToast('Anomalía reportada con éxito', {
        appearance: 'success',
        autoDismiss: true,
      });
      onHide();
    } catch (error: any) {
      addToast(`Error al reportar la anomalía: ${error}`, {
        appearance: 'error',
      });
    } finally {
      loadingToastId && removeToast(loadingToastId);
      setIsSubmitting(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <FontAwesomeIcon icon={faExclamationTriangle} className="text-warning me-2" />
          Reportar Anomalía - {orderName}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Descripción</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Describa la anomalía encontrada en la interpretación del pedido..."
              required
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Enviando...' : 'Enviar'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default withToastManager(ReportAnomalyModal);
