import XLSX from 'xlsx';
import config from '../config';
import { DownloadFileType, EMAIL_REGEX } from '../constants';
import { DataTableExportColumnProps } from '../types/dataTable';
import { saveAs } from 'file-saver';
import APIClient from '../services/APIClient';
import { ExportConfig } from '../types/dataTable';
import { ToastFunction } from '../types/toast';

// XLSX librarie
export const generateWorkbookObject = (arrayData) => {
  const wb = XLSX.utils.book_new();
  wb.SheetNames.push('1');
  const ws_data = arrayData; //a row with 2 columns
  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  wb.Sheets['1'] = ws;
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
  return wbout;
};

export const s2ab = (s) => {
  var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  var view = new Uint8Array(buf); //create uint8array as viewer
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
  return buf;
};

export const CSVToArrays = (csv) => {
  const rowsArray = csv.split('┴');
  const response = rowsArray.map((rowString, index) => {
    if (index === 0) {
      return rowString.split(',');
    }
    return rowString.split('|');
  });
  return response;
};

// ///
export const parseDataToExport = async (
  data,
  headersCol: DataTableExportColumnProps[],
  exportExtraFields,
  customHeaders,
) => {
  let headers = headersCol.map((col) => col.dataField);

  let headerNames =
    customHeaders[0] === '' ? headersCol.map((col) => col.dataField) : customHeaders;
  if (exportExtraFields[0] !== '') {
    headers = headers.concat(exportExtraFields);
    headerNames = headerNames.concat(exportExtraFields);
  }
  const dataColumns = data.map((datO) =>
    headers.map((h) => {
      // nested data
      if (h.indexOf('.') > 0) {
        // only for level 1 nested (i.e. nested.length = 2)
        const nested = h.split('.');
        return datO[nested[0]] ? datO[nested[0]][nested[1]] : '';
      }
      return datO[h];
    }),
  );
  dataColumns.unshift(
    headerNames.map((h) => {
      if (h.indexOf('.') > 0) {
        return h.split('.')[0];
      }
      return h;
    }),
  );
  return dataColumns;
};

export const getNowDateLocale = () => {
  const dateNow = Date.now();
  const dateString = new Date(dateNow);
  return dateString.toLocaleDateString(config.date.locale);
};

export const getSimplifiedMimeType = (mimeType) => {
  return mimeType.split(';')[0];
};

export const getTypeFromMimeType = (mimeType): DownloadFileType => {
  const simplifiedMimeType = getSimplifiedMimeType(mimeType);
  const audioMimeTypes = [
    'audio/mpeg',
    'video/mp4',
    'audio/wav',
    'application/ogg',
    'audio/ogg',
    'audio/weba',
    'video/webm',
  ];
  if (simplifiedMimeType.startsWith('audio') || audioMimeTypes.includes(simplifiedMimeType)) {
    return DownloadFileType.audio;
  }

  const imageMimeTypes = [
    'image/jpeg',
    'image/png',
    'image/webp',
    'image/gif',
    'image/avif',
    'image/tiff',
    'image/svg+xml',
    'image/bmp',
  ];
  if (simplifiedMimeType.startsWith('image') || imageMimeTypes.includes(simplifiedMimeType)) {
    return DownloadFileType.image;
  }

  const spreadsheetMimeTypes = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];
  if (spreadsheetMimeTypes.includes(simplifiedMimeType)) {
    return DownloadFileType.spreadsheet;
  }

  const wordMimeTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
  if (wordMimeTypes.includes(simplifiedMimeType)) {
    return DownloadFileType.word;
  }

  const pdfMimeTypes = ['application/pdf'];
  if (pdfMimeTypes.includes(simplifiedMimeType)) {
    return DownloadFileType.pdf;
  }

  const csvMimeTypes = ['text/csv'];
  if (csvMimeTypes.includes(simplifiedMimeType)) {
    return DownloadFileType.csv;
  }
  return DownloadFileType.document;
};

/**
 * Adapts the query parameters based on export options
 *
 * @param config - The original export configuration
 * @param options - Export options
 * @returns The adjusted export configuration
 */
export const adaptQueryParams = (
  config: ExportConfig | undefined,
  options: {
    exportSelectedOnly?: boolean;
    selectedRowIds?: string[];
  },
): ExportConfig | undefined => {
  if (!config) return config;

  const { exportSelectedOnly, selectedRowIds } = options;
  const shouldFilterByIds = exportSelectedOnly && selectedRowIds && selectedRowIds.length > 0;

  let adjustedConfig = { ...config };

  if (adjustedConfig.exportURL && typeof adjustedConfig.exportURL === 'string') {
    let baseUrl = adjustedConfig.exportURL;
    const queryIndex = baseUrl.indexOf('?');

    if (queryIndex !== -1) {
      baseUrl = baseUrl.substring(0, queryIndex);
    }

    const searchParams = new URLSearchParams();

    const currentUrl = new URL(
      adjustedConfig.exportURL.startsWith('http')
        ? adjustedConfig.exportURL
        : `${window.location.origin}${adjustedConfig.exportURL}`,
    );

    currentUrl.searchParams.forEach((value, key) => {
      if (key !== 'undefined') {
        searchParams.set(key, value);
      }
    });
    searchParams.delete('limit');
    searchParams.delete('offset');

    if (config.columns) {
      searchParams.set('exportConfig', JSON.stringify({ columns: config.columns }));
    }

    if (shouldFilterByIds) {
      searchParams.set('filter[id][in]', selectedRowIds.join(','));
    }
    adjustedConfig.exportURL = `${baseUrl}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`;
  }

  return adjustedConfig;
};

export interface ExportEntityRecordsParams {
  exportConfig: ExportConfig | undefined;
  selectedRowIds?: string[];
  exportSelectedOnly?: boolean;
  onGetExportData?: () => Promise<any[]>;
  propsTP: any;
  exportCols: any[];
  exportExtraFields?: string[];
  exportCustomHeaders?: string[];
  exportFileName?: string;
  addToast: ToastFunction;
}

export const exportEntityRecords = async (options: ExportEntityRecordsParams): Promise<void> => {
  const {
    exportConfig,
    selectedRowIds,
    exportSelectedOnly = false,
    onGetExportData,
    propsTP,
    exportCols,
    exportExtraFields,
    exportCustomHeaders,
    exportFileName,
    addToast,
  } = options;

  try {
    const toastMessage = exportSelectedOnly
      ? `Descargando reporte de filas seleccionadas...`
      : `Descargando reporte...`;

    addToast(toastMessage, {
      appearance: 'info',
      autoDismiss: true,
    });

    let formatedDataToExport: string[][] | undefined;
    let filename: string = '';

    if (exportConfig) {
      const adjustedExportConfig = adaptQueryParams(exportConfig, {
        exportSelectedOnly,
        selectedRowIds,
      });

      const response = await APIClient.get(adjustedExportConfig!.exportURL);

      if (response.data.data && typeof response.data.data !== 'string') {
        const filenameSuffix = exportSelectedOnly ? `_seleccionados` : '';
        filename = `${exportFileName}${filenameSuffix}_${getNowDateLocale().replaceAll('/', '-')}.${config.export.extension}`;

        formatedDataToExport = await parseDataToExport(
          response.data.data,
          exportCols,
          exportExtraFields,
          exportCustomHeaders,
        );
      } else if (response.data && typeof response.data === 'string') {
        filename = response.headers['content-disposition'].split(';')[1].substr(10);
        formatedDataToExport = await CSVToArrays(response.data);
      }
    } else if (onGetExportData) {
      const filenameSuffix = exportSelectedOnly ? `_seleccionados` : '';
      filename = `${exportFileName}${filenameSuffix}_${getNowDateLocale().replaceAll('/', '-')}.${config.export.extension}`;

      const exportData = await onGetExportData();
      formatedDataToExport = await parseDataToExport(
        exportData,
        exportCols,
        exportExtraFields,
        exportCustomHeaders,
      );
    } else {
      propsTP.csvProps.onExport();
      return;
    }

    const wbout = await generateWorkbookObject(formatedDataToExport);
    const s2abOut = await s2ab(wbout);
    const blobObject = new Blob([s2abOut], { type: 'application/octet-stream' });
    saveAs(blobObject, filename);

    addToast(`Reporte ${filename} descargado correctamente.`, {
      appearance: 'success',
      autoDismiss: true,
    });
  } catch (err) {
    console.error('Error descargando el reporte.', err);
    addToast('Error descargando el reporte.', {
      appearance: 'error',
      autoDismiss: true,
    });
  }
};

export const shouldOpenInBrowser = (mimeType: DownloadFileType) =>
  mimeType === DownloadFileType.pdf ||
  mimeType === DownloadFileType.text ||
  mimeType === DownloadFileType.image;
  
/**
 * Updates all <a> elements in the given text to open in a new tab (`target="_blank"`).
 * Ensures that:
 * - `href` values do not contain extra spaces.
 * - If a `target` attribute exists, it is replaced with `_blank`.
 * - If no `target` attribute exists, `_blank` is added.
 * 
 * @param text The input string containing HTML links.
 * @returns The modified string with updated `<a>` elements.
 */
export const setLinksTargetBlank = (text: string) => {
  return text.replace(/<a\s+([^>]*\bhref\s*=\s*"([^"]+)"[^>]*)>/gi, (match, attr: string) => {
    
    attr = attr.replace(/href="([^"]+)"/, (_, hrefValue) => {
      return `href="${hrefValue.trim()}"`;
    });

    if (/target="([^"]+)"/.test(attr)) {
      attr = attr.replace(/target="([^"]+)"/, (_, targetValue) => {
        return `target="_blank"`;
      });
    } else {
      attr += ' target="_blank"';
    }
    
    return `<a ${attr}>`;
  });
}