import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import axios, { AxiosResponse } from 'axios';
import { EntityEditForm, FormInputField, FormCheckField, FormSelectField } from '../../components';
import APIClient from '../../services/APIClient';
import { WithRouterProps, withRouter } from '../withRouter';
import FormSelectCustom, { SelectCustomOption } from '../../components/FormSelectCustom';
import { compareUserRoles } from '../Utils';
import { Role, User } from '../../types/model';

function UsuarioEdit({ navigate, params, toastManager }: WithRouterProps) {
  const { id } = params;
  const isAdding = typeof id === 'undefined';

  const [entity, setEntity] = React.useState<User>();
  const [vendedores, setVendedores] = React.useState([]);
  const [roles, setRoles] = React.useState<Role[]>([]);
  const [selectedRoles, setSelectedRoles] = React.useState<Role[]>([]);
  const [unselectedRoles, setUnselectedRoles] = React.useState<Role[]>([]);

  async function handleFormLoad() {
    // get vendedores
    const vendedoresResponse = await APIClient.get('/agents');
    // get roles
    const roles = await APIClient.get('/role');

    setVendedores(vendedoresResponse.data.data);
    setRoles(roles.data.data);
  }

  async function handleClientSelectLoadOptions(query: string): Promise<SelectCustomOption[]> {
    const res = await APIClient.get(
      `/clientes?filter[razonSocial][like]=${encodeURIComponent(`%${query}%`)}`,
    );
    const dataFetched = res.data.data;
    const optionsFormatted = dataFetched.map((data: any) => ({
      value: data.id,
      label: data.razonSocial,
    }));
    return optionsFormatted;
  }

  async function handleFormRetrieveEntity() {
    // get usuario
    const usuarioRes = await APIClient.get(`/usuarios/${id}`);
    const entity = usuarioRes.data.data;
    setEntity(entity);
    return entity;
  }

  function handlerRolesChecks(e, role: Role) {
    const { checked } = e.target;

    const existRol = entity?.roles ? entity.roles.some((userRol) => userRol.id === role.id) : false;
    let newRoles = selectedRoles;
    let newUnselectedRoles = unselectedRoles;

    if (checked) {
      if (!existRol) {
        newRoles.push(role);
      }

      newUnselectedRoles = newUnselectedRoles.filter(
        (unselectedRole) => unselectedRole.id !== role.id,
      );
    } else {
      if (existRol) {
        newUnselectedRoles.push(role);
      }

      newRoles = newRoles.filter((e) => e.id !== role.id);
    }

    setSelectedRoles(newRoles);
    setUnselectedRoles(newUnselectedRoles);
  }

  /**
   * Save the item
   */
  async function handleFormSaveEntity(entityToSave) {
    try {
      entityToSave.roles = selectedRoles;
      entityToSave.rolesToUpdate = unselectedRoles;
      let saveResponse: AxiosResponse;
      if (isAdding) {
        saveResponse = await APIClient.post('/usuarios', entityToSave);
      } else {
        const rolesToUpdate = {
          rolesToRemove: unselectedRoles,
          rolesToAdd: selectedRoles,
        };
        await APIClient.patch(`/usuarios/roles?userId=${id}`, rolesToUpdate);
        saveResponse = await APIClient.patch(`/usuarios/${id}`, entityToSave);
      }

      navigate('/usuarios');
      if (isAdding) {
        toastManager.add(
          <>
            <p>{`Usuario ${saveResponse.data.data.id} guardado con éxito`}</p>
            <p>
              Se ha generado automáticamente la contraseña:
              <br />
              <strong>{saveResponse.data.data.newPassword}</strong>
            </p>
          </>,
          {
            appearance: 'success',
          },
        );
      } else {
        toastManager.add(`Usuario ${saveResponse.data.data.id} guardado con éxito`, {
          appearance: 'success',
          autoDismiss: true,
        });
      }
      return saveResponse.data.data;
    } catch (error) {
      let errorMessage = (error as Error)?.message;
      if (axios.isAxiosError(error) && error.response && error.response.data?.message) {
        errorMessage = error.response.data.message;
      }
      toastManager.add(`Error al momento de guardar el usuario: ${errorMessage}`, {
        appearance: 'error',
      });
    }
  }

  return (
    <div>
      <h1 className="page-title">{isAdding ? 'Usuario nuevo' : `Usuario #${id}`}</h1>

      <EntityEditForm
        onLoadForm={handleFormLoad}
        onRetrieveEntity={handleFormRetrieveEntity}
        onSaveEntity={handleFormSaveEntity}
        addMode={isAdding}>
        <>
          <Row>
            <Col md={6}>
              <FormInputField
                id="firstName"
                label="Nombre"
                as="input"
                defaultValue={entity?.firstName}
                required
              />
              <FormInputField
                id="lastName"
                label="Apellido"
                as="input"
                defaultValue={entity?.lastName}
                required
              />
            </Col>
            <Col md={6}>
              <FormInputField
                id="username"
                label="Username (login)"
                as="input"
                defaultValue={entity?.username}
                required
              />
              <FormInputField
                id="emailAddress"
                label="E-mail"
                as="input"
                type="email"
                defaultValue={entity?.emailAddress}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormSelectCustom
                id="perfil.clienteId"
                label="Cliente"
                selectedOption={{
                  value: entity?.perfil?.clienteId,
                  label: entity?.perfil?.Cliente?.razonSocial,
                }}
                onLoadOptions={handleClientSelectLoadOptions}
              />
            </Col>
            <Col md={6}>
              <FormSelectField
                id="perfil.vendedorId"
                label="Ejecutivo comercial"
                defaultValue={entity?.perfil?.vendedorId}
                choices={vendedores}
                choiceIdField="id"
                choiceLabelField="nombre"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-2">
              <FormCheckField id="isActive" label="Activo" defaultChecked={entity?.isActive} />
            </Col>
            <Col md={12} className="mb-3">
              <Card>
                <Card.Header>Permisos de usuario</Card.Header>
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      <Card>
                        <Card.Header>Permisos disponibles</Card.Header>
                        {roles.map((role) => (
                          <Card.Body key={role.id}>
                            <FormCheckField
                              id={`rol_${role.id}`}
                              label={role.descripcion}
                              onChange={(e) => handlerRolesChecks(e, role)}
                              defaultChecked={
                                entity?.roles ? compareUserRoles(entity.roles, role) : false
                              }
                            />
                          </Card.Body>
                        ))}
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card>
                        <Card.Header>Permisos administrativos</Card.Header>
                        <Card.Body>
                          <FormCheckField
                            id="isSuperAdmin"
                            label="Super Adminsitrador"
                            defaultChecked={entity?.isSuperAdmin}
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      </EntityEditForm>
    </div>
  );
}

export default withToastManager(withRouter(UsuarioEdit));
