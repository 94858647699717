import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';
import { DataTableExportCSVButtonProps } from '../types/dataTable';
import { exportEntityRecords } from './utils'

function DataTableExportCSVButton({
  propsTP,
  exportCustomHeaders,
  exportExtraFields,
  exportFileName,
  exportConfig,
  exportCols,
  disabled,
}: DataTableExportCSVButtonProps) {
  
  const { addToast } = useToasts();
  const handleClick = async () => {
    await exportEntityRecords({
      exportConfig,
      exportSelectedOnly: false,
      propsTP,
      exportCols,
      exportExtraFields,
      exportCustomHeaders,
      exportFileName,
      addToast,
    });
  };

  return (
    <div>
      <Button
        className="d-flex align-items-center justify-content-center"
        variant="primary"
        onClick={handleClick}
        disabled={disabled}>
        <FontAwesomeIcon icon={faFileExcel} fixedWidth />
        <span>Exportar todos</span>
      </Button>
    </div>
  );
}

export default DataTableExportCSVButton;
