import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Form, InputGroup, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import axios from 'axios';
import { withToastManager } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import {
  DataTable,
  EntityEditForm,
  FormAddressInput,
  FormInputField,
  Loading,
} from '../../components';
import ContactList, { ContactForm } from '../../components/ContactList';
import APIClient from '../../services/APIClient';
import Utils from '../Utils';
import UIUtils from '../UIUtils';
import { ClientEditState, FullPuntoEntrega, InitSocialMedia } from './clientInterface';
import FormInput from '../../components/componentsTs/FormInput';
import FormSelect from '../../components/componentsTs/FormSelect';
import { isObjectEmpty } from '../../utils';
import { WithRouterProps, withRouter } from '../withRouter';
import { formatAmount } from '../Utils';
import { DeliveryPoint, Zona } from '../../types/model';

class ClienteEdit extends React.Component<WithRouterProps, ClientEditState> {
  static propTypes = {
    params: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props: WithRouterProps) {
    super(props);

    const { id } = props.params;
    const pageNumbUrl = Utils.sanitizeQuery(['page'], props.location.search).page;
    const pageNum = parseInt(pageNumbUrl);
    const page = Utils.isPositiveInteger(pageNum) ? pageNum : 1;
    // init values for social media array
    const initSocialMedia = { urlUsername: '', socialMediaCode: '' };
    this.state = {
      id,
      page,
      isAdding: typeof id === 'undefined',
      selectedLista: {},
      entity: {},
      tipos: [],
      vendedores: [],
      listasPrecio: [],
      puntosEntrega: [],
      selectedListaId: null,
      isDataLoading: typeof id === 'undefined',
      showModal: false,
      condicionesPago: [],
      zonas: [],
      estados: [],
      socialMedia: [initSocialMedia],
      provincias: [],
      clienteId: id,
      contacts: [],
      productMatchInstructions: '',
      hasProductMatchInstructions: false,
      showConfirmMergeContactModal: false,
      existingContact: undefined,
    };
  }

  //#region Render functions  -------------------------------------
  renderConfirmMergeContactModal = () => {
    const { showConfirmMergeContactModal, existingContact } = this.state;
    return (
      <Modal
        size="lg"
        show={showConfirmMergeContactModal}
        onHide={this.handleConfirmMergeContactCloseButtonClick}>
        <Modal.Header closeButton>
          <Modal.Title>Asociar contacto existente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>El contacto ya existe, ¿deseas asociarlo ahora?</p>
          <p>Nombre: {existingContact?.firstName}</p>
          <p>Apellido: {existingContact?.lastName}</p>
          <p>Teléfono: {existingContact?.phoneNumber}</p>
          <p>Email: {existingContact?.email}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleConfirmMergeContactCloseButtonClick}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={this.handleConfirmMergeContactButtonClick}>
            Asociar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderListaPrecioDetailModal = () => {
    // create table for lista precio
    const { isDataLoading, selectedLista, showModal } = this.state;
    const columns = [
      {
        dataField: 'Product.descripcion',
        text: 'Producto',
        sort: true,
      },
      {
        dataField: 'precio',
        text: 'Precio',
        sort: true,
        classes: 'text-end',
        formatter: (cellContent) => {
          return cellContent ? (
            formatAmount(cellContent)
          ) : (
            <span className="text-danger font-weight-bold">(No tiene precio)</span>
          );
        },
      },
    ];

    return (
      <Modal size="lg" show={showModal} onHide={this.handleClose}>
        <Modal.Header closeButton>
          {selectedLista !== undefined && !isObjectEmpty(selectedLista) ? (
            <Modal.Title>{selectedLista.descripcion}</Modal.Title>
          ) : (
            <Modal.Title>Lista de Precio</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          {selectedLista !== undefined && !isObjectEmpty(selectedLista) ? (
            <DataTable
              isDataLoading={isDataLoading}
              columns={columns}
              data={selectedLista.detalles || []}
              keyField="id"
            />
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={this.handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    );
  };

  //#endregion Render functions  -------------------------------------

  //#region Events handlers  -------------------------------------

  handleClose = () => {
    this.setState({ showModal: false });
  };

  handleConfirmMergeContactCloseButtonClick = () => {
    this.setState({ showConfirmMergeContactModal: false });
  };

  handleConfirmMergeContactButtonClick = async () => {
    const { existingContact, id, contacts } = this.state;
    const { toastManager } = this.props;

    try {
      if (existingContact) {
        await APIClient.post(`/clientes/contacts/${existingContact.id}/associate`, {
          clientId: id,
        });
        this.setState({ contacts: [...contacts, existingContact] });
      }
    } catch (error) {
      return toastManager.add(`Ocurrió un error al momento de asociar el contacto ${error}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    } finally {
      this.setState({ showConfirmMergeContactModal: false });
    }
  };

  onLoadForm = async () => {
    this.setState({ isDataLoading: true });
    // VERRR
    const { clienteId } = this.state;
    // get cliente estado
    const estadosRes = await APIClient.get('/cliente-estados?filter[id][or]=1&filter[id][or]=2');
    // get cliente tipos
    const tiposRes = await APIClient.get('/cliente-tipos');
    // get vendedores
    const vendedoresRes = await APIClient.get('/agents');
    // get lista precio
    const listasPrecioRes = await APIClient.get('/listas-precio');
    // get lista precio
    const puntosEntregaRes = await APIClient.get(`/clientes/${clienteId}/puntos-entrega`);
    // get lista de contactos
    const contacts = await APIClient.get(`/clientes/${clienteId}/contacts`);

    const puntosDeEntrega = puntosEntregaRes.data.data.map((pe: FullPuntoEntrega) => {
      pe.descripcion = `${pe.descripcion} - ${pe.calle} ${
        pe.numero === '00' ? '' : ' ' + pe.numero
      } ${!pe.piso ? '' : ' ' + pe.piso} ${!pe.depto ? '' : ' ' + pe.depto}
      ${pe.localidad} ${
        !pe.codigoPostal || pe.codigoPostal === '0' ? '' : ' cp: ' + pe.codigoPostal
      }
     ${!pe.provincia ? '' : ' ' + pe.provincia.descripcion}`;
      return pe;
    });

    // get provincias
    const provinciasRes = await APIClient.get('/provincias');
    // get condiciones pago
    const condicionesPago = await APIClient.get('/condiciones-pago');
    // get zonas
    const zonasRes = await APIClient.get('/zonas');
    this.setState({
      tipos: tiposRes.data.data,
      estados: estadosRes.data.data,
      vendedores: vendedoresRes.data.data,
      listasPrecio: listasPrecioRes.data.data,
      provincias: provinciasRes.data.data,
      puntosEntrega: puntosDeEntrega,
      condicionesPago: condicionesPago.data.data,
      zonas: zonasRes.data.data.filter((e: Zona) => e.isVisible === true),
      contacts: contacts.data.data,
    });
  };

  onAddSocialMedia = () => {
    const { socialMedia, isAdding, id } = this.state;
    const newSocialMedia: InitSocialMedia = {
      urlUsername: '',
      socialMediaCode: '',
      // this property is to identify if an existing customer
      // will have a new social media
      newForUpdate: !isAdding,
    };
    if (isAdding === false && id) {
      newSocialMedia.customerId = id;
    }
    socialMedia.push(newSocialMedia);
    const newSocialMediaArray = socialMedia;
    this.setState({
      socialMedia: newSocialMediaArray,
    });
  };

  onRetrieveEntity = async () => {
    const { id } = this.state;
    // get cliente
    const clienteRes = await APIClient.get(`/clientes/${id}`);
    const puntosEntregaRes = await APIClient.get(`/clientes/${id}/puntos-entrega`);
    const puntosDeEntrega = puntosEntregaRes.data.data.map((pe: DeliveryPoint) => {
      pe.descripcion = `${pe.descripcion} - ${pe.calle} ${
        pe.numero === '00' ? '' : ' ' + pe.numero
      } ${!pe.piso ? '' : ' ' + pe.piso} ${!pe.depto ? '' : ' ' + pe.depto}
      ${pe.localidad} ${
        !pe.codigoPostal || pe.codigoPostal === '0' ? '' : ' cp: ' + pe.codigoPostal
      }
     ${!pe.provincia ? '' : ' ' + pe.provincia.descripcion}`;
      return pe;
    });
    this.setState({
      entity: clienteRes.data.data,
      selectedListaId: clienteRes.data.data.listaPrecioId,
      puntosEntrega: puntosDeEntrega,
      socialMedia: clienteRes.data.data.socialMedias,
      isDataLoading: false,
      productMatchInstructions: clienteRes.data.data?.aiParams?.productMatchInstructions,
      hasProductMatchInstructions: !!clienteRes.data.data?.aiParams,
    });
    return clienteRes.data.data;
  };

  /**
   * Save the item
   */
  onSaveEntity = async (entityToSave: any) => {
    const { id, page, isAdding, socialMedia } = this.state;
    const { navigate, toastManager } = this.props;

    // parse customer social media data
    const entityToSaveKeys = Object.keys(entityToSave);
    entityToSaveKeys.forEach((e) => {
      // find if any customer social media will be updated
      const isUsernameUpdate = /urlUsername/;
      const issocialMediaCodeUpdate = /socialMediaCode/;
      // organize data in array
      if (isUsernameUpdate.test(e)) {
        const index = Number(e.slice(12));
        socialMedia[index].urlUsername = entityToSave[e];
      }
      if (issocialMediaCodeUpdate.test(e)) {
        const index = Number(e.slice(16));
        socialMedia[index].socialMediaCode = entityToSave[e];
      }
    });
    entityToSave.socialMedias = socialMedia;
    let saveResponse;
    try {
      if (isAdding) {
        saveResponse = await APIClient.post('/clientes', entityToSave);
        APIClient.get('/clientes/actualizarCodigoInterno');
      } else {
        saveResponse = await APIClient.patch(`/clientes/${id}`, entityToSave);
      }
      navigate(`/clientes?page=${page}`);
      toastManager.add(`Cliente ${saveResponse.data.data.id} guardado con éxito`, {
        appearance: 'success',
        autoDismiss: true,
      });
      return saveResponse.data.data;
    } catch (error) {
      return toastManager.add(`Ocurrió un error al momento de guardar el cliente ${error}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  handleSaveContact = async (updatedContact: ContactForm) => {
    const { id, contacts } = this.state;
    const { toastManager } = this.props;
    try {
      let updatedContacts = contacts;
      if (updatedContact.id) {
        await APIClient.patch(`/clientes/contacts/${updatedContact.id}`, updatedContact);
        updatedContacts = contacts.map((contact) => {
          if (contact.id === updatedContact.id) {
            return { ...contact, ...updatedContact };
          } else {
            return contact;
          }
        });
      } else {
        const contactCreateRes = await APIClient.post(`/clientes/${id}/contacts`, updatedContact);
        const createdContact = contactCreateRes.data.data;
        updatedContacts = [...contacts, createdContact];
      }

      this.setState({
        contacts: updatedContacts,
      });

      return toastManager.add('Contacto actualizado', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response?.status == 409) {
        const errorResponseData = error.response?.data.data;
        const isAssociated: boolean = errorResponseData?.isAssociated ?? false;
        const existingContact = errorResponseData?.existingContact;

        if (isAssociated) {
          return toastManager.add('El contacto ya está asociado', {
            appearance: 'error',
            autoDismiss: true,
          });
        }

        this.setState({
          existingContact: existingContact,
          showConfirmMergeContactModal: true,
        });
      } else {
        return toastManager.add('Ocurrió un error inesperado al guardar el contacto.', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
  };

  handleDeleteContact = async (contactId: number) => {
    const { id } = this.state;
    const { toastManager } = this.props;
    try {
      await APIClient.delete(`/clientes/${id}/contacts/${contactId}`);

      this.setState((prevState) => ({
        contacts: prevState.contacts.filter((contact) => contact.id !== contactId),
      }));
      return toastManager.add('Contacto desvinculado', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (error) {
      console.error('Error unlinking contact', error);
      return toastManager.add(`Ocurrió un error al momento de desvincular el contacto ${error}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  /* Intelligence Parameters */

  handleProductMatchInstructionsChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    this.setState({
      productMatchInstructions: value,
    });
  };

  handleSaveProductMatchInstructionsButtonClick = async () => {
    const { id, productMatchInstructions, hasProductMatchInstructions } = this.state;
    const { toastManager } = this.props;
    try {
      if (hasProductMatchInstructions) {
        await APIClient.patch(`/clientes/${id}/ai-params`, { productMatchInstructions });
      } else {
        await APIClient.post(`/clientes/${id}/ai-params`, { productMatchInstructions });
        this.setState({ hasProductMatchInstructions: true });
      }

      return toastManager.add('Instrucción guardada', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (error) {
      console.error('Error al guardar la instrucción', error);
      return toastManager.add(`Ocurrió un error al momento de guardar la instrucción ${error}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  //#endregion Events handlers  -------------------------------------

  showModalConfirmMergeContact = () => {
    this.setState({ showConfirmMergeContactModal: true });
  };

  saveSelectedListaId = (e: any) => {
    this.setState({ selectedListaId: e.target.value });
  };

  showModalAndGetProducts = async () => {
    const { selectedListaId } = this.state;
    // get products
    const selectedLista = await APIClient.get(`/listas-precio/${selectedListaId}`);
    this.setState({ selectedLista: selectedLista.data.data, showModal: true });
  };

  render() {
    const {
      id,
      entity,
      tipos,
      estados,
      vendedores,
      listasPrecio,
      provincias,
      puntosEntrega,
      isAdding,
      selectedListaId,
      condicionesPago,
      zonas,
      socialMedia,
      contacts,
      isDataLoading,
    } = this.state;

    return (
      <div>
        {isAdding ? (
          <h1 className="page-title"> Cliente nuevo </h1>
        ) : (
          <Row className="page-title">
            <Col className="d-flex justify-content-between pe-0" md={12}>
              <h1 className="d-flex my-3">
                {`Cliente ${
                  !isDataLoading ? (entity.erpCode ? `(Cod: ${entity.erpCode})` : `#${id}`) : ''
                }`}
                {/* {entity && entity.estado ? (
                  <div className="ms-3 d-flex">
                    {UIUtils.getClienteEstadoBadge(entity.estado.descripcion)}
                  </div>
                ) : null} */}
              </h1>
            </Col>
          </Row>
        )}
        {this.renderListaPrecioDetailModal()}
        {this.renderConfirmMergeContactModal()}

        <Tabs defaultActiveKey="main">
          <Tab eventKey="main" title="Información principal">
            <EntityEditForm
              onLoadForm={this.onLoadForm}
              onRetrieveEntity={this.onRetrieveEntity}
              onSaveEntity={this.onSaveEntity}
              addMode={isAdding}>
              <>
                <Card className="mb-2">
                  <Card.Body>
                    <Card.Title>Información Principal</Card.Title>
                    <Row>
                      <Col md={6}>
                        <FormInput
                          id="razonSocial"
                          disabled
                          label="Razón Social"
                          type="text"
                          defaultValue={entity.razonSocial}
                          required
                        />
                      </Col>
                      <Col md={6}>
                        <FormInput
                          id="documentoNumero"
                          disabled
                          label="Código Fiscal"
                          type="text"
                          defaultValue={entity.documentoNumero}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormInput
                          id="nombreFantasia"
                          disabled
                          label="Nombre de Fantasía"
                          type="text"
                          defaultValue={entity.nombreFantasia}
                        />
                      </Col>
                      <Col md={6}>
                        <FormInput
                          id="erpCode"
                          disabled
                          label="Código interno"
                          type="text"
                          defaultValue={entity.erpCode}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormSelect
                          id="tipoId"
                          label="Tipo"
                          defaultValue={entity.tipoId}
                          choices={tipos}
                          choiceIdField="id"
                          choiceLabelField="descripcion"
                          disabled
                        />
                      </Col>

                      <Col md={6}>
                        <FormSelect
                          id="estadoId"
                          label="Estado"
                          defaultValue={entity.estadoId}
                          choices={estados}
                          choiceIdField="id"
                          choiceLabelField="descripcion"
                          required
                          disabled
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card className="mb-2">
                  <Card.Body>
                    <Card.Title>Información de Contacto</Card.Title>
                    <Row>
                      <Col md={6}>
                        <FormInput
                          id="contacto"
                          disabled
                          label="Contacto Principal"
                          defaultValue={entity.contacto}
                        />
                      </Col>
                      <Col md={6}>
                        <FormInput
                          id="telefono"
                          disabled
                          label="Teléfono Principal"
                          type="text"
                          defaultValue={entity.telefono}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormInput
                          id="celular"
                          disabled
                          label="Teléfono móvil"
                          type="text"
                          defaultValue={entity.celular}
                        />
                      </Col>
                      <Col md={6}>
                        <FormInput
                          id="email"
                          disabled
                          label="Email Principal"
                          type="text"
                          defaultValue={entity.email}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormInput
                          id="contactoComercial"
                          disabled
                          label="Contacto Comercial"
                          type="text"
                          defaultValue={entity.contactoComercial}
                        />
                      </Col>
                      <Col md={6}>
                        <FormInput
                          id="telefonoComercial"
                          disabled
                          label="Teléfono Comercial"
                          type="tel"
                          defaultValue={entity.telefonoComercial}
                        />
                      </Col>
                      <Col md={6}>
                        <FormInput
                          id="emailComercial"
                          disabled
                          label="Email Comercial"
                          type="text"
                          defaultValue={entity.emailComercial}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormInput
                          id="contactoCobranzas"
                          disabled
                          label="Contacto Cobranzas"
                          type="text"
                          defaultValue={entity.contactoCobranzas}
                        />
                      </Col>
                      <Col md={6}>
                        <FormInput
                          id="telefonoCobranzas"
                          disabled
                          label="Teléfono Cobranzas"
                          type="tel"
                          defaultValue={entity.telefonoCobranzas}
                        />
                      </Col>
                      <Col md={6}>
                        <FormInput
                          id="emailCobranzas"
                          disabled
                          label="Email Cobranzas"
                          type="text"
                          defaultValue={entity.emailCobranzas}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormAddressInput
                          disabled={true}
                          provincias={provincias}
                          addMode={isAdding}
                          entity={entity}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormInput
                          id="longitud"
                          disabled
                          label="Longitud"
                          type="text"
                          defaultValue={entity.longitud}
                        />
                      </Col>
                      <Col md={6}>
                        <FormInput
                          id="latitud"
                          disabled
                          label="Latitud"
                          type="text"
                          defaultValue={entity.latitud}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body>
                    <Card.Title>Información Comercial</Card.Title>
                    <Row>
                      <Col md={6}>
                        <FormSelect
                          id="vendedorId"
                          label="Ejecutivo comercial"
                          defaultValue={entity.vendedorId}
                          choices={vendedores}
                          choiceIdField="id"
                          choiceLabelField="nombre"
                          disabled
                        />
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="listaPrecioId" className="mt-3">
                          <Form.Label>Lista de Precio</Form.Label>
                          <InputGroup>
                            <Form.Control
                              as="select"
                              onChange={this.saveSelectedListaId}
                              name="listaPrecioId"
                              disabled
                              defaultValue={entity.listaPrecioId}>
                              {isAdding && <option value="">(Seleccione)</option>}
                              {listasPrecio.map((lista) => (
                                <option key={lista.id} value={lista.id}>
                                  {lista.descripcion}
                                </option>
                              ))}
                            </Form.Control>
                            {selectedListaId === null || selectedListaId === '' ? null : (
                              <Button
                                onClick={this.showModalAndGetProducts}
                                size="sm"
                                variant="outline-secondary"
                                title="Detalle">
                                <FontAwesomeIcon icon={faInfoCircle} fixedWidth size="sm" />
                              </Button>
                            )}
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormSelect
                          id="puntoEntregaId"
                          label="Punto de Entrega"
                          defaultValue={entity.puntoEntregaId}
                          choices={puntosEntrega}
                          choiceIdField="id"
                          choiceLabelField="descripcion"
                          required
                          disabled
                        />
                      </Col>
                      <Col md={6}>
                        <FormSelect
                          id="zonaId"
                          label="Zona"
                          defaultValue={entity.zonaId}
                          choices={zonas}
                          choiceIdField="id"
                          choiceLabelField="descripcion"
                          disabled
                        />
                      </Col>
                      <Col md={4}>
                        <FormInput
                          id="entregaHorario"
                          disabled
                          label="Horario de Entrega"
                          defaultValue={entity.entregaHorario}
                        />
                      </Col>
                      <Col md={2}>
                        <Row>
                          <Col>
                            <Row className="align-items-end">
                              <Col md={6}>
                                <FormInput
                                  id="diasEntrega"
                                  label="Entrega en"
                                  defaultValue={entity.diasEntrega}
                                  min={0}
                                  disabled
                                />
                              </Col>
                              <Col md={6}>
                                <p className="mb-0">dias.</p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <FormSelect
                          id="condicionPagoId"
                          label="Condición de Pago"
                          defaultValue={entity.condicionPagoId}
                          choices={condicionesPago}
                          choiceIdField="id"
                          disabled
                          choiceLabelField="descripcion"
                        />
                      </Col>
                    </Row>
                    {/* <Row>
                  <Col md={8}>
                    <FormSwitch
                      id="excludeFromAverage-switch"
                      label={`Excluir del promedio de venta diario.
                        ${
                          entity.excludeFromAverageDailySale
                            ? ' La funcionalidad está activada para este cliente'
                            : ' La funcionalidad no está activada para este cliente'
                        }`}
                      checked={entity.excludeFromAverageDailySale}
                      labelField="excludeFromAverageDailySale"
                    />
                  </Col>
                </Row> */}
                  </Card.Body>
                </Card>
              </>
            </EntityEditForm>
          </Tab>
          <Tab eventKey="ai-params" title="Parametros de inteligencia">
            <FormInputField
              id="productMatchInstructions"
              label="Instrucciones para la coincidencia de productos"
              as="textarea"
              defaultValue={entity?.aiParams?.productMatchInstructions}
              onChange={this.handleProductMatchInstructionsChange}
            />
            <div className="d-flex">
              <Button
                className="btn btn-primary mt-2"
                onClick={this.handleSaveProductMatchInstructionsButtonClick}>
                Guardar Instrucción
              </Button>
            </div>
          </Tab>
          <Tab eventKey="contacts" title="Contactos">
            {isDataLoading ? (
              <Loading />
            ) : (
              <ContactList
                contacts={contacts}
                onSaveContact={this.handleSaveContact}
                onDeleteContact={this.handleDeleteContact}
              />
            )}
          </Tab>
        </Tabs>
      </div>
    );
  }
}

export default withToastManager(withRouter(ClienteEdit));
