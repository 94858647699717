import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Fade, Modal, Row, Table } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComments,
  faCopy,
  faMagic,
  faRobot,
  faExclamationTriangle,
  faSave,
  faCheckSquare,
} from '@fortawesome/free-solid-svg-icons';
import { AxiosError, AxiosResponse, isAxiosError } from 'axios';
import moment from 'moment';
import APIClient from '../../services/APIClient';
import {
  EntityEditForm,
  FormInputField,
  FormSelectField,
  Loading,
  ConversationContainer,
} from '../../components';
import FormSelectCustom from '../../components/FormSelectCustom';
import OrderItemTableRow from '../../components/OrderItemTableRow';
import { StatusCode } from '../../constants';
import { withRouter } from '../withRouter';
import UIUtils from '../UIUtils';
import { renderSourceChannel, generateDeliveryString, formatAmount } from '../Utils';
import {
  AIExecution,
  Agent,
  Conversation,
  DeliveryPoint,
  InterpretOrderResult,
  OrderItem,
  PaymentMethod,
  PriceList,
  PriceListDetail,
  PriceListResume,
  Product,
  OrderClientMatchByFields,
  Contact,
  Order,
} from '../../types/model';
import apiClient from '../../services/APIClient';
import config from '../../config';
import { saveAs } from 'file-saver';
import FileDisplay from '../../components/FileDisplay';
import ReportAnomalyModal from '../../components/ReportAnomalyModal';

function OrderAIEdit({ navigate, params, toastManager }) {
  const [agents, setAgents] = useState<Agent[]>([]);
  const [aiExecution, setAiExecution] = useState<AIExecution | null>(null);
  const [aiExecutionResultOrder, setAiExecutionResultOrder] = useState<InterpretOrderResult | null>(
    null,
  );
  const [client, setClient] = useState<any>(null);
  const [clientMatchHint, setClientMatchHint] = useState<string | null>(null);
  const [conversation, setConversation] = useState<Conversation>();
  const [entity, setEntity] = useState<any>({});
  const [deliveryDate, setDeliveryDate] = useState<string>();
  const [deliveryPoints, setDeliveryPoints] = useState<DeliveryPoint[]>([]);
  const [deliveryPointId, setDeliveryPointId] = useState<number | null>(null);
  const [id, setId] = useState<number>(parseInt(params.id));
  const [isAdding, setIsAdding] = useState<boolean>(typeof id === 'undefined');
  const [isAiDataVisible, setisAiDataVisible] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isItemsModified, setIsItemsModified] = useState<boolean>(false);
  const [showPurgeModal, setShowPurgeModal] = useState<boolean>(false);
  const [isLoadingCopyText, setIsLoadingCopyText] = useState<boolean>(false);
  const [itemsToDelete, setItemsToDelete] = useState<number[]>([]);
  const [orderItems, setOrderItems] = useState<OrderItem[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [priceListDetails, setPriceListDetails] = useState<PriceListDetail[] | null>(null);
  const [priceLists, setPriceLists] = useState<PriceListResume[]>([]);
  const [selectedPriceListId, setSelectedPriceListId] = useState<number | null>(null);
  const [showAiMessageModal, setShowAiMessageModal] = useState<boolean>(false);
  const [totalAmount, setTotalAmount] = useState<number>(0);

  const [clientAiIconColor, setClientAiIconColor] = useState<string | null>();
  const [clientAiIconText, setClientAiIconText] = useState<string | null>();
  const [deliveryDateAiIconColor, setDeliveryDateAiIconColor] = useState<string | null>();
  const [deliveryDateAiIconText, setDeliveryDateAiIconText] = useState<string | null>();
  const [deliveryPointAiIconColor, setDeliveryPointAiIconColor] = useState<string | null>();
  const [deliveryPointAiIconText, setDeliveryPointAiIconText] = useState<string | null>();
  const [confirmOnSave, setConfirmOnSave] = useState<boolean>(false);

  const [showAnomalyModal, setShowAnomalyModal] = useState<boolean>(false);

  useEffect(() => {
    if (!isEditable) {
      // if not editable, use prices in items
      return;
    }
    // update prices with the selected price list
    const localOrderItems = updatePrices(orderItems);
    setOrderItems(localOrderItems);
  }, [priceListDetails]);

  useEffect(() => {
    updateTotal();
  }, [orderItems]);

  useLayoutEffect(() => {
    computeAiInfo();
  }, [client, deliveryDate, deliveryPointId]);

  function updatePrices(items: OrderItem[]): OrderItem[] {
    const updatedOrderItems = items.map((item) => {
      item.precio = Number(
        priceListDetails ? priceListDetails.find((priceItem) => priceItem.productId === item.productId)?.precio ?? 0.0 : 0.0,
      );
      item.listaPrecioId = entity.listaPrecioId;
      return item;
    });

    return updatedOrderItems;
  }

  /**
   * Resets the prices of all items in the order to the original price or null, and also resets the priceListId to null.
   * This is used when the user wants to stop using a price list and instead use the original price of the items.
   */
  function resetItemsPrices() {
    const updatedOrderItems = orderItems.map((item) => {
      item.precio = item.originalPrice ?? null;
      item.listaPrecioId = null; // reset the price list too
      return item;
    });
    setOrderItems(updatedOrderItems);
  }

  function updateTotal() {
    let localTotal = 0.0;

    orderItems.forEach((item) => {
      localTotal += (item.precio ?? 0.0) * (item.cantidad ?? 0);
    });

    setTotalAmount(localTotal);
  }

  function computeAiInfo() {
    // Function to compute color and text
    if (aiExecutionResultOrder === null) {
      return;
    }

    if (aiExecutionResultOrder.client.matchBy === 'none' || !aiExecutionResultOrder?.client?.id) {
      setClientAiIconColor('red');
      setClientAiIconText('No se ha detectado cliente');
    } else if (aiExecutionResultOrder.client.matchBy === 'missing') {
      setClientAiIconColor('orange');
      setClientAiIconText('El cliente interpretado no se encontró en la base de datos');
    } else if (aiExecutionResultOrder.client?.id == client?.id) {
      setClientAiIconColor('green');
      setClientAiIconText('Cliente interpretado por IA');
    } else {
      setClientAiIconColor('cornflowerblue');
      setClientAiIconText('Cliente modificado por el usuario');
    }

    if (aiExecutionResultOrder?.deliveryDate == null) {
      setDeliveryDateAiIconColor('red');
      setDeliveryDateAiIconText('No se ha detectado fecha de entrega');
    } else if (aiExecutionResultOrder?.deliveryDate.split('T')[0] === deliveryDate!.split('T')[0]) {
      setDeliveryDateAiIconColor('green');
      setDeliveryDateAiIconText('Fecha de entrega interpretada por IA');
    } else {
      setDeliveryDateAiIconColor('cornflowerblue');
      setDeliveryDateAiIconText('Fecha de entrega modificada por el usuario');
    }

    if (aiExecutionResultOrder.deliveryPoint.matchBy === 'missing') {
      setDeliveryPointAiIconColor('orange');
      setDeliveryPointAiIconText('No se ha detectado punto de entrega');
    } else if (aiExecutionResultOrder.deliveryPoint.matchBy === 'none') {
      setDeliveryPointAiIconColor('red');
      setDeliveryPointAiIconText(
        'El punto de entrega interpretado no se encontró en la base de datos',
      );
    } else if (aiExecutionResultOrder.client?.id == client?.id) {
      setDeliveryPointAiIconColor('green');
      setDeliveryPointAiIconText('Punto de entrega interpretado por IA');
    } else {
      setDeliveryPointAiIconColor('cornflowerblue');
      setDeliveryPointAiIconText('Punto de entrega modificado por el usuario');
    }
  }

  //#region Entity edit form ------------------------------------

  /* FETCHING INITIAL DATA */
  function onLoadForm() {
    // get working data asynchronously
    Promise.all([
      APIClient.get('/listas-precio'),
      APIClient.get('/agents'),
      APIClient.get('/formas-pago'),
    ])
      .then((responses: AxiosResponse[]) => {
        // adding default values to the lists
        const responseData = responses.map((r) => r.data.data);

        responseData[0].unshift({ id: '', descripcion: 'Seleccione lista de precio' });
        setPriceLists(responseData[0]);

        responseData[1].unshift({ id: '', nombre: 'Seleccione vendedor' });
        setAgents(responseData[1]);

        responseData[2].unshift({ id: '', description: 'Seleccione forma de pago' });
        setPaymentMethods(responseData[2]);
      })
      .catch((error) => {
        toastManager.add('Error cargando información del formulario.', {
          appearance: 'error',
        });
        console.error('Error cargando información del formulario.', error);
      });
  }

  useEffect(() => {
    const fetchPriceListDetails = async (priceListId: number) => {
      try {
        const response = await APIClient.get(`/listas-precio/${priceListId}`);

        setPriceListDetails(response.data.data.detalles || []);
      } catch (error) {
        console.error('Error fetching price list details:', error);
        toastManager.add('Error al cargar los detalles de la lista de precios', {
          appearance: 'error',
        });
      }
    };

    const priceListId = selectedPriceListId || entity.listaPrecioId;

    if (priceListId) {
      fetchPriceListDetails(priceListId);
    }
  }, [entity.listaPrecioId, selectedPriceListId, toastManager]);

  async function onRetrieveEntity() {
    const localEntity: Order = (await APIClient.get(`/orders/${id}`)).data.data;

    const localOrderItems: OrderItem[] = [...(localEntity.Items ?? [])];

    let localAiExecution: AIExecution | null = null;
    let localAiExecutionResultOrder: InterpretOrderResult | null = null;

    if (localEntity.aiExecutionId) {
      // request AI order
      localAiExecution = (await APIClient.get(`/ai/executions/${localEntity.aiExecutionId}`)).data
        .data;

      if (localAiExecution !== null) {
        // parse the AI result order
        localAiExecutionResultOrder = JSON.parse(localAiExecution.resultOrder);

        APIClient.get(`/conversations/by-ai-execution/${localEntity.aiExecutionId}`)
          .then((response) => {
            setConversation(response.data.data);
          })
          .catch((error) => {
            const axiosError = error as AxiosError;
            if (axiosError && axiosError.response?.status == 404) {
              console.warn('No conversation found.');
            } else {
              console.error('Error al recuperar conversación', error);
            }
            setConversation(undefined);
          });
      }

      setAiExecutionResultOrder(localAiExecutionResultOrder);

      setAiExecution(localAiExecution);

      // set client match hint
      if (localAiExecutionResultOrder?.client?.hint) {
        setClientMatchHint(localAiExecutionResultOrder.client.hint);
      } else {
        switch (localAiExecutionResultOrder?.client?.matchBy) {
          case OrderClientMatchByFields.deliveryPoint: {
            setClientMatchHint('el punto de entrega');
            break;
          }
          case OrderClientMatchByFields.id: {
            if (localAiExecutionResultOrder?.client?.contactId) {
              apiClient
                .get(`/contacts/${localAiExecutionResultOrder.client.contactId}`)
                .then((response) => {
                  let localContact: Contact = response.data.data;
                  if (localContact.firstName || localContact.lastName) {
                    setClientMatchHint(`${localContact?.firstName} ${localContact.lastName}`);
                  }
                });
            }
            break;
          }
          default:
            setClientMatchHint(null);
        }
      }
    }

    // prepare the items array to display
    // create a new item for each one in the order
    const newOrderItems = localOrderItems.map((orderItem) => {
      orderItem.originalPrice = orderItem.precio; // copy the original price, if exists
      if (orderItem.aiOrderItemIndex !== null && localAiExecutionResultOrder) {
        orderItem.aiData = localAiExecutionResultOrder.items[orderItem.aiOrderItemIndex];
      }
      orderItem.listaPrecioId = localEntity.listaPrecioId;
      orderItem.keyData = orderItem.id ? `ID_${orderItem.id}` : String(Math.random());
      return orderItem;
    });

    if (localEntity.Cliente?.id) {
      // request delivery points based on client
      const deliveryPointsData = (
        await APIClient.get(`/clientes/${localEntity.Cliente.id}/puntos-entrega`)
      ).data.data;

      setClient(localEntity.Cliente);
      setDeliveryPoints(deliveryPointsData);
    }

    if (
      !isAdding &&
      localEntity.estadoOrderCodigo.match(
        new RegExp(`${StatusCode.draft}|${StatusCode.deliveredPartial}|${StatusCode.pending}`),
      )
    ) {
      // enabling edit mode
      setIsEditable(true);
    }

    // Don't apply prices on loading, because the order might have prices before
    // if (localEntity.listaPrecioId) {
    //   onChangePriceList(localEntity.listaPrecioId);
    // }

    setDeliveryDate(String(localEntity.fechaEntrega));
    setOrderItems(newOrderItems);
    setEntity(localEntity);
    return localEntity;
  }

  function prepareToSave(entityToSave: any) {
    // removes data not stored in model

    const orderItemsProcessed = orderItems
      .map((orderItem) => {
        if (orderItem.id === null && orderItem.Product == null) {
          return null;
        }

        const itemProcessed = { ...orderItem };

        delete itemProcessed.aiData;
        delete itemProcessed.keyData;
        delete itemProcessed.Product;

        return itemProcessed;
      })
      .filter((i) => !!i); // removes NULL/empty items

    entityToSave.Items = orderItemsProcessed;

    if (itemsToDelete.length > 0) {
      entityToSave.assocToDelete = {};
      entityToSave.assocToDelete.Items = [...itemsToDelete];
    }

    if (entityToSave?.deliveryPointId === '') {
      entityToSave.deliveryPointId = null;
    }

    if (entityToSave?.clienteId === '') {
      entityToSave.clienteId = null;
    }

    if (entityToSave?.fechaEntrega === '') {
      entityToSave.fechaEntrega = null;
    }
    
    return entityToSave;
  }

  async function onSaveEntity(entityToSave: any) {
    let saveData: any;

    if (isAdding) {
      saveData = (await APIClient.post('/orders', entityToSave)).data.data;
    } else {
      saveData = (await APIClient.patch(`/orders/${id}`, entityToSave)).data.data;
    }

    let successMessage = `Pedido ${saveData.codigo} guardado con éxito`;
    
    if (confirmOnSave) {
      try {
        await APIClient.patch(`/orders/${saveData.id}/change-status`, {
          estadoOrderCodigo: StatusCode.confirmed
        });
        successMessage = `Pedido ${saveData.codigo} guardado y verificado con éxito`;
      } catch (error) {
        let errorMessage = isAxiosError(error) && error.response?.data.message || error;
        toastManager.add(`Hubo un error al confirmar el pedido: ${errorMessage}`, {
          appearance: 'warning',
        });
      }
    }

    toastManager.add(
      successMessage,
      {
        appearance: 'success',
        autoDismiss: true,
      },
      () => navigate('/orders'),
    );
    return saveData;
  }

  function handleFormValidate(entityToSave: Order): string | undefined {
    let validationMessage: string | undefined = undefined;

    // valdate items
    for (let i = 0; i < orderItems.length; i++) {
      const orderItem = orderItems[i];

      if (orderItem.cantidad === null || orderItem.cantidad <= 0) {
        validationMessage = `La cantidad del item ${i + 1} debe ser mayor a 0.`;
        break;
      }
    }

    return validationMessage;
  }

  //#endregion Entity edit form ------------------------------------

  //#region Events ------------------------------------

  /* EVENT HANDLERS */
  async function handleSelectCustomClientChange(e: any) {
    if (e.target.value === null) {
      return;
    }
    // get the client data and then the delivery points
    const clientData = (await APIClient.get(`/clientes/${e.target.value}`)).data.data;

    const deliveryPointsData = (await APIClient.get(`/clientes/${e.target.value}/puntos-entrega`))
      .data.data;

    setClient(clientData ?? null);
    setDeliveryPoints(deliveryPointsData ?? []);
  }

  async function handleSelectCustomClientLoadOptions(query: string) {
    const clientData = (
      await APIClient.get(`/clientes?freeText=${encodeURIComponent(`%${query}%`)}`)
    ).data.data;

    const optionsFormatted = clientData.map((data) => {
      const erpCode = data?.erpCode ? `[${data.erpCode}] ` : '';

      return {
        value: data.id,
        label: `${erpCode}${data.razonSocial}`,
        subLabel: data.nombreFantasia,
      };
    });

    return optionsFormatted;
  }

  function handleDeliveryDateChange(e: any) {
    setDeliveryDate(e.target.value);
  }

  function handleDeliveryPointChange(e: any) {
    setDeliveryPointId(e.target.value);
  }

  function handlePurgeItemsClick() {
    const orderItemsCopy = [...orderItems];

    // Filter and map uninterpreted items
    const uninterpretedItemIds = orderItemsCopy
      .filter((item) => item.aiData?.matchBy === 'none' && !item.Product?.id)
      .map((item) => item.id)
      .filter((id): id is number => id !== null);

    // Filter local order items that are not in the delete list
    const updatedOrderItems = orderItemsCopy.filter(
      (item) => item.id && ![...itemsToDelete, ...uninterpretedItemIds].includes(item.id),
    );

    // Update the lists of items to delete and the order items
    setItemsToDelete([...itemsToDelete, ...uninterpretedItemIds]);
    setOrderItems(updatedOrderItems);
    setShowPurgeModal(false);
  }

  async function handleItemChange(
    index: number,
    productId: number,
    quantity: number | null,
    unitId: number | null,
  ) {
    const localOrderItems = [...orderItems];
    localOrderItems[index].productId = productId;

    // search for repeated items
    const localOrderItemsFiltered = localOrderItems.filter(
      (orderItem) => orderItem.productId === productId,
    );

    if (localOrderItemsFiltered.length > 1) {
      // if item repeated, inform and remove the repeated
      toastManager.add('El producto ya se encuentra en la orden.', {
        appearance: 'warning',
        autoDismiss: true,
      });

      localOrderItems.splice(index, 1);
      setOrderItems(localOrderItems);
      return;
    }

    let item = localOrderItems[index];

    // updates Product if required
    if (productId != item.Product?.id) {
      const productData: Product = (await APIClient.get(`/products/${productId}`)).data.data;

      item.Product = productData;
      setIsItemsModified(true);
    }

    // updates quantity and unit
    item.cantidad = quantity;
    item.unitId = unitId;

    // rebuild the items list and store it
    item = updatePrices([item])[0];
    localOrderItems[index] = item;
    setOrderItems(localOrderItems);
  }

  function handleItemDelete(index) {
    const localOrderItems = [...orderItems];
    const localItemsToDelete = [...itemsToDelete];

    // remove the item from state
    const itemToDelete = localOrderItems.splice(index, 1)[0];

    if (itemToDelete.id !== null) {
      // stores the id of the item to be removed if it's stored in db
      localItemsToDelete.push(itemToDelete.id);
      setItemsToDelete(localItemsToDelete);
    }

    setOrderItems(localOrderItems);
    return;
  }

  function handleItemCreate() {
    let isOrderItemEmpty = false;
    orderItems.forEach((orderItem) => {
      // if the item is not stored in db and has no productId
      if (orderItem.id === null && orderItem?.Product == null) {
        toastManager.add('Complete el ítem vacío antes de agregar uno nuevo.', {
          appearance: 'warning',
          autoDismiss: true,
        });
        isOrderItemEmpty = true;
        return;
      }
    });

    if (isOrderItemEmpty) {
      // return without creating new item
      return;
    }

    const localOrderItems = [...orderItems];
    localOrderItems.push(generateOrderItemTemplate());
    setOrderItems(localOrderItems);
  }

  async function onChangePriceList(e: React.ChangeEvent<HTMLSelectElement>) {
    if (!e.target.value) {
      // reset the prices to the original prices
      resetItemsPrices();
      return;
    }

    const localPriceListId = parseInt(e.target.value);

    setSelectedPriceListId(localPriceListId);
  }

  async function handleCopyTextClick(e: any) {
    e.preventDefault();

    try {
      setIsLoadingCopyText(true);
      const resumeData = (await APIClient.get(`/orders/${id}/plain-text`)).data.data;
      navigator.clipboard.writeText(resumeData);
      toastManager.add('Pedido copiado al portapapeles.', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (error: any) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    } finally {
      setIsLoadingCopyText(false);
    }
  }

  function handleCloseModalButtonClick() {
    setShowAiMessageModal(false);
  }

  function handleOpenModalButtonClick() {
    setShowAiMessageModal(true);
  }

  async function handleDownloadFile(fileId: number | null) {
    try {
      const response = await APIClient.getFile(`${config.api.url}/files/${fileId}`, {
        responseType: 'arraybuffer',
      });

      const blob = new Blob([response.data], {
        type: response.headers['content-type'] || 'application/octet-stream',
      });

      const contentDisposition = response.headers['content-disposition'];
      const fileName = contentDisposition
        ? contentDisposition.split('filename=')[1].trim()
        : `file_${new Date().getTime()}`;

      saveAs(blob, fileName);
    } catch (error: any) {
      toastManager.add(`Ocurrió un error al descargar el archivo: "${error.message}"`, {
        appearance: 'error',
      });
    }
  }

  function handleCloseModalPurgeItems() {
    setShowPurgeModal(false);
  }

  function handleOpenModalPurgeItems() {
    setShowPurgeModal(true);
  }
  
  function handleSaveAndConfirmClick() {
    setConfirmOnSave(true)
  }

  function handleReportAnomalyClick() {
    setShowAnomalyModal(true);
  }

  function handleAnomalyModalClose() {
    setShowAnomalyModal(false);
  }

  //#endregion Events ------------------------------------

  function generateOrderItemTemplate(): OrderItem {
    return {
      id: null,
      orderId: id,
      productId: null,
      posicion: 9999,
      cantidad: 1,
      unitId: null,
      precio: null,
      listaPrecioId: selectedPriceListId,
      entregado: 0,
      modificadoresPrecio: '',
      aiOrderItemIndex: null,
      keyData: String(Math.random()),
    };
  }

  //#region Render functions ------------------------------------

  function renderOrderInfoMessage() {
    const created = new Date(entity.createdAt);
    const updated = new Date(entity.updatedAt);
    const creatorUserFullName =
      entity.UsuarioPerfil?.Usuario &&
      `${entity.UsuarioPerfil?.Usuario?.firstName} ${entity.UsuarioPerfil?.Usuario.lastName}`;

    const message = (
      <>
        Creado el <strong>{created.toLocaleString()}</strong>
        {creatorUserFullName && (
          <>
            {' por '}
            <strong>{creatorUserFullName}</strong>
          </>
        )}
        {' a través de '}
        <strong>{renderSourceChannel(entity.sourceChannel)}</strong>
      </>
    );

    if (updated.getTime() - created.getTime() < 3 * 60 * 1000) {
      return <>{message}.</>;
    }
    return (
      <>
        {message} y actualizado el <strong>{updated.toLocaleString()}</strong>.
      </>
    );
  }

  const renderAiMessageModal = () => (
    <Modal size="lg" show={showAiMessageModal} onHide={handleCloseModalButtonClick}>
      <Modal.Header closeButton>
        <Modal.Title>Mensaje interpretado</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {conversation ? (
          <ConversationContainer conversation={conversation} />
        ) : aiExecution?.files && aiExecution.files.length > 0 ? (
          <>
            <p>Este pedido fue procesado en base a los siguientes archivos:</p>
            {/* <Button
              onClick={() => handleDownloadFile(aiExecution.fileId)}
              title="Descargar archivo adjunto"
              aria-label="Descargar archivo adjunto">
              <FontAwesomeIcon icon={faDownload} fixedWidth /> Descargar archivo
            </Button> */}
            <div className="d-flex flex-row flex-wrap">
              {aiExecution.files.map((file) => (
                <FileDisplay file={file} key={file.id} />
              ))}
            </div>
          </>
        ) : aiExecution?.request ? (
          <textarea
            className="w-100"
            style={{ height: '25rem' }}
            value={aiExecution.request}
            readOnly
          />
        ) : (
          <p>Conversación no disponible.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModalButtonClick}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const renderPurgeItemsModal = () => (
    <Modal size="lg" show={showPurgeModal} onHide={handleCloseModalPurgeItems}>
      <Modal.Header closeButton>
        <Modal.Title>Eliminar ítems no identificados</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>¿Está seguro de que desea eliminar estos ítems?</p>
        <p>
          Se eliminarán todos los ítems que no pudieron ser interpretados correctamente ( marcados
          con <FontAwesomeIcon icon={faMagic} color={'tomato'} fixedWidth /> ). Asegúrese de revisar
          los ítems afectados usando el botón ( <FontAwesomeIcon icon={faRobot} fixedWidth /> Info
          IA ) para ver el mensaje original.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => handlePurgeItemsClick()}>
          Eliminar
        </Button>
        <Button variant="secondary" onClick={handleCloseModalPurgeItems}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  //#endregion Render functions ------------------------------------

  const hours = UIUtils.generateHours();

  const getClientSelectedOption = () => {
    const erpCode = entity.Cliente?.erpCode ? `[${entity.Cliente?.erpCode}] ` : '';
    const label = entity.Cliente?.id ? `${erpCode}${entity.Cliente?.razonSocial}` : '-';
    const subLabel = entity.Cliente?.nombreFantasia;

    return {
      value: entity?.clienteId ?? null,
      label: label,
      subLabel: subLabel,
    };
  };

  return (
    <>
      {renderAiMessageModal()}
      {renderPurgeItemsModal()}
      <ReportAnomalyModal
        show={showAnomalyModal}
        onHide={handleAnomalyModalClose}
        orderId={id}
        orderName={entity.codigo ? `Pedido ${entity.codigo}` : `Pedido #${id}`}
      />
      <EntityEditForm
        onLoadForm={onLoadForm}
        onRetrieveEntity={onRetrieveEntity}
        onSaveEntity={onSaveEntity}
        onValidate={handleFormValidate}
        addMode={isAdding}
        isEditable={isEditable}
        isModified={isItemsModified}
        prepareToSave={prepareToSave}
        buttons={[
          {
            text: 'Guardar y Verificar',
            type: 'submit',
            variant: 'success',
            classes: 'me-1',
            icon: <FontAwesomeIcon icon={faCheckSquare} fixedWidth className="me-1" />,
            hidden: ![StatusCode.pending, StatusCode.draft].includes(entity.estadoOrderCodigo),
            action: handleSaveAndConfirmClick,
          },
          {
            text: 'Guardar',
            type: 'submit',
            variant: 'primary',
            classes: 'me-1',
            icon: <FontAwesomeIcon icon={faSave} fixedWidth className="me-1" />,
          },
        ]}
        >
        <Row>
          <Col md={8}>
            <h1 className="d-flex mt-3 me-2">
              {isAdding ? 'Pedido nuevo ' : 'Pedido '}
              {entity.codigo === null ? `#${id}` : entity.codigo && `${entity.codigo}`}
              <div className="ms-3">
                {UIUtils.getSolicitudEstadoBadge(entity?.estadoOrderCodigo, entity?.erpStatusCode)}
              </div>
            </h1>
            <p className="mb-3 text-muted">{renderOrderInfoMessage()}</p>
          </Col>
          <Col md={4} className="d-flex align-items-center justify-content-end">
            <ButtonGroup aria-label="Operaciones de Pedido">
              {/* Only render these 2 buttons if there is AI info */}
              {aiExecutionResultOrder && (
                <>
                  <Button
                    onClick={() => setisAiDataVisible(!isAiDataVisible)}
                    title="Información de IA">
                    <FontAwesomeIcon icon={faRobot} fixedWidth />
                  </Button>

                  <Button
                    className={!aiExecutionResultOrder ? 'd-none' : ''}
                    onClick={handleOpenModalButtonClick}
                    title="Ver conversación original">
                    <FontAwesomeIcon icon={faComments} fixedWidth />
                  </Button>
                </>
              )}
              <Button
                onClick={handleCopyTextClick}
                disabled={isLoadingCopyText}
                title="Copiar al portapapeles">
                {!isLoadingCopyText ? (
                  <FontAwesomeIcon icon={faCopy} fixedWidth title="Copiar al portapapeles" />
                ) : (
                  <Loading className="" />
                )}
              </Button>
              {aiExecutionResultOrder && (
                <Button onClick={handleReportAnomalyClick} title="Reportar anomalía">
                  <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />
                </Button>
              )}
            </ButtonGroup>
          </Col>
        </Row>

        {/* FIRST ROW */}
        <Row>
          <Col xl={6}>
            <Row>
              <FormSelectCustom
                id="clienteId"
                label="Cliente"
                disabled={!isEditable}
                onLoadOptions={handleSelectCustomClientLoadOptions}
                onChange={handleSelectCustomClientChange}
                selectedOption={getClientSelectedOption()}
                aiStatusIconColor={clientAiIconColor ?? undefined}
                aiInterpretDescription={clientAiIconText ?? undefined}
              />
            </Row>
            {/*If we don't add this check, will generate an empty space in the row*/}
            {isAiDataVisible &&
              aiExecutionResultOrder?.client.matchBy !== 'none' &&
              clientMatchHint && (
                <Row>
                  <Fade in={isAiDataVisible}>
                    <div className="text-muted mt-1">
                      <FontAwesomeIcon icon={faRobot} fixedWidth />
                      <span>
                        &nbsp;Se ha utilizado <strong>{clientMatchHint}</strong> para detectar al
                        cliente.
                      </span>
                    </div>
                  </Fade>
                </Row>
              )}
          </Col>
          {/*
              <Col md={6}>
                <Row>
                  <FormInputField
                    id="fechaVencimiento"
                    label="Fecha de vencimiento"
                    type="date"
                    defaultValue={
                      entity.vencimiento ? moment.utc(entity.vencimiento).format('YYYY-MM-DD') : ''
                    }
                    min={moment().format('YYYY-MM-DD')}
                    disabled={!isEditable}
                  />
                </Row>
              </Col>
              */}
        </Row>

        {/* SECOND ROW */}
        <Row>
          <Col sm={12} md={6} xl={4}>
            <FormSelectField
              id="vendedorId"
              label="Ejecutivo comercial"
              defaultValue={entity.vendedorId}
              choices={agents}
              choiceIdField="id"
              choiceLabelField="nombre"
              disabled={!isEditable}
              required
            />
          </Col>
          <Col sm={12} md={6} xl={4}>
            <FormInputField
              id="purchaseOrder"
              label="Orden de compra"
              defaultValue={entity.purchaseOrder}
              disabled={!isEditable}
            />
          </Col>
          <Col sm={12} md={6} xl={4}>
            <FormSelectField
              id="listaPrecioId"
              label="Lista de precios"
              defaultValue={entity.listaPrecioId ?? null}
              choices={priceLists}
              choiceIdField="id"
              choiceLabelField="descripcion"
              onChange={onChangePriceList}
              disabled={!isEditable}
              required
            />
          </Col>
          {/*
          <Col md={3}>
            <FormSelectField
              id="formaPagoId"
              label="Forma de pago"
              defaultValue={entity.formaPagoId}
              choices={paymentMethods}
              choiceIdField="id"
              choiceLabelField="description"
              disabled={!isEditable}
              required
            />
          </Col>
          <Col md={3}>
            <FormInputField
              id="facturaTipo"
              label="Tipo de factura"
              defaultValue={entity.facturaTipo}
              disabled={!isEditable}
            />
          </Col>
          */}
        </Row>
        {/*
        {entity.transferencistaName && (
          <Row>
            <Col md={6}>
              <div className="form-group">
                <label className="form-label">Transferencista</label>
                <p className="form-control-plaintext">{entity.transferencistaName}</p>
              </div>
            </Col>
          </Row>
        )}
          */}

        {/* FOURTH ROW - DELIVERY */}
        <h2 className="mt-2">Entrega</h2>
        <Row>
          <Col sm={6} xl={4}>
            <FormInputField
              id="fechaEntrega"
              type="date"
              label="Fecha de entrega"
              defaultValue={moment.utc(entity.fechaEntrega).format('YYYY-MM-DD')}
              min={moment().format('YYYY-MM-DD')}
              disabled={!isEditable}
              aiStatusIconColor={deliveryDateAiIconColor ?? undefined}
              aiInterpretDescription={deliveryDateAiIconText ?? undefined}
              onChange={handleDeliveryDateChange}
            />
            <Fade in={isAiDataVisible}>
              <div className="flex-vertical-center-start mt-1 text-muted">
                <FontAwesomeIcon icon={faRobot} fixedWidth />
                {moment.utc(aiExecutionResultOrder?.deliveryDate).format('LL')}
              </div>
            </Fade>
          </Col>
          {/*
              <Col md={4}>
                <FormSelectField
                  label="Desde"
                  id="horaEntregaDesde"
                  defaultValue={entity.horaEntregaDesde}
                  choices={hours}
                  choiceIdField="hour"
                  choiceLabelField="hour"
                  disabled={!isEditable}
                />
              </Col>
              <Col md={4}>
                <FormSelectField
                  label="Hasta"
                  id="horaEntregaHasta"
                  defaultValue={entity.horaEntregaHasta}
                  choices={hours}
                  choiceIdField="hour"
                  choiceLabelField="hour"
                  disabled={!isEditable}
                />
              </Col>
              */}
          <Col md={6}>
            <FormSelectCustom
              id="puntoEntregaId"
              label="Punto de entrega"
              disabled={!isEditable}
              options={deliveryPoints.map((point) => ({
                value: point.id.toString(),
                label: generateDeliveryString(point) ?? '',
              }))}
              selectedOption={{
                value: entity?.PuntoEntrega?.id,
                label: generateDeliveryString(entity?.PuntoEntrega) ?? '',
              }}
              onChange={handleDeliveryPointChange}
              aiStatusIconColor={deliveryPointAiIconColor ?? undefined}
              aiInterpretDescription={deliveryPointAiIconText ?? undefined}
            />
          </Col>
        </Row>
        <Row className="mt-1 mb-5">
          <Col md={12}>
            <FormInputField
              id="observaciones"
              label="Observaciones"
              disabled={!isEditable}
              as="textarea"
              defaultValue={entity?.observaciones}
            />
          </Col>
        </Row>
        {/* FIFTH ROW - ITEMS */}
        <h2 className="mt-2">
          Items
          {` (${orderItems?.length ?? 0})`}
        </h2>
        <div className="" style={{ overflowX: 'scroll' }}>
          <Table hover>
            <thead>
              <tr>
                <th style={{ minWidth: '1rem' }}></th>
                <th style={{ minWidth: '6rem', maxWidth: '10rem' }}>Código</th>
                <th style={{ minWidth: '25rem', width: 'auto' }}>Descripción</th>
                <th style={{ minWidth: '8rem', maxWidth: '15rem' }} className="text-start">
                  Cantidad
                </th>
                <th style={{ minWidth: '7rem', maxWidth: '12rem' }} className="text-start">
                  Entregado
                </th>
                <th style={{ minWidth: '6rem', maxWidth: '10rem' }} className="text-end">
                  Precio U.
                </th>
                <th style={{ minWidth: '6rem', maxWidth: '10rem' }} className="text-end">
                  Total
                </th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody key={priceListDetails?.length ?? 0}>
              {orderItems &&
                orderItems.map((item, index) => (
                  <OrderItemTableRow
                    key={item.keyData}
                    index={index}
                    item={item}
                    disabled={!isEditable}
                    isAiDataVisible={isAiDataVisible}
                    onChange={handleItemChange}
                    onDelete={handleItemDelete}
                    priceListDetails={priceListDetails}
                  />
                ))}
            </tbody>
            <tfoot>
              <tr className="text-end">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="fw-bold">Total</td>
                <td className="fw-bold">{formatAmount(totalAmount)}</td>
                <td></td>
              </tr>
            </tfoot>
          </Table>
        </div>
        {isEditable && (
          <div className="d-flex mt-3">
            <Button
              variant="outline-primary"
              onClick={handleItemCreate}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              Agregar ítem
            </Button>

            <Button
              disabled={
                !orderItems.some((item) => item.aiData?.matchBy === 'none' && !item.Product?.id)
              }
              className="mx-1"
              variant="warning"
              onClick={handleOpenModalPurgeItems}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              Eliminar items no interpretados
            </Button>
          </div>
        )}
      </EntityEditForm>
    </>
  );
}

export default withToastManager(withRouter(OrderAIEdit));
