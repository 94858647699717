import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { ConnectorSummary, Unit } from '../types/model';

type AppContextState = {
  connectors: ConnectorSummary[];
  units: Unit[];
};

const initialContextValue: AppContextState = {
  connectors: [],
  units: [],
};

export const AppContext = createContext<AppContextState>(initialContextValue);

//#region Dispatch >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export enum AppDispatchActionTypes {
  SET_CONNECTORS = 'SET_CONNECTORS',
  SET_UNITS = 'SET_UNITS',
}

type AppDispatchAction =
  | {
      type: AppDispatchActionTypes.SET_CONNECTORS;
      payload: ConnectorSummary[];
    }
  | {
      type: AppDispatchActionTypes.SET_UNITS;
      payload: Unit[];
    };

function appContextReducer(state: AppContextState, action: AppDispatchAction) {
  switch (action.type) {
    case AppDispatchActionTypes.SET_CONNECTORS: {
      return {
        ...state,
        connectors: action.payload,
      };
    }
    case AppDispatchActionTypes.SET_UNITS: {
      return {
        ...state,
        units: action.payload,
      };
    }
    // default: {
    //   throw Error('Unknown action: ' + action.type);
    // }
  }
}

export const AppDispatchContext = createContext<Dispatch<AppDispatchAction> | null>(null);

//#endregion Dispatch <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

/**
 * Provider for the AppContext and AppDispatchContext combined.
 *
 * @param {{ children: ReactNode }} props The props object.
 * @param {ReactNode} props.children The children to be wrapped.
 * @returns {ReactElement} The AppContext.Provider component.
 */
export function AppContextProvider({ children }) {
  const [appState, dispatch] = useReducer(appContextReducer, initialContextValue);

  return (
    <AppContext.Provider value={appState}>
      <AppDispatchContext.Provider value={dispatch}>{children}</AppDispatchContext.Provider>
    </AppContext.Provider>
  );
}

/**
 * Hook to access the AppContext state and AppDispatchContext dispatch.
 *
 * @returns {{ state: AppContextState, dispatch: Dispatch<AppDispatchAction> }} An object containing the
 * AppContext state and AppDispatchContext dispatch.
 *
 * @throws {Error} if the context or dispatchContext is not defined.
 */
export function useAppContext(): {
  appState: AppContextState;
  appDispatch: Dispatch<AppDispatchAction>;
} {
  const context = useContext(AppContext);
  const dispatchContext = useContext(AppDispatchContext);
  if (!context || !dispatchContext) {
    throw new Error('context or dispatchContext not defined');
  }
  return { appState: context, appDispatch: dispatchContext };
}
