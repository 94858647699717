import { Route } from 'react-router-dom';
import PasswordChange from './PasswordChange';
import UserProfile from './UserProfile';

const routes = () =>
  [
    <Route path="/profile" element={<UserProfile />} key="/profile" />,
    <Route path="/change-password" element={<PasswordChange />} key="/change-password" />,
  ].map((route) => route);

export default routes;
