import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import moment from 'moment';
import { Conversation } from '../../types/model';
import APIClient from '../../services/APIClient';
import { withRouter } from '../withRouter';
import { SelectCustomOption } from '../../components/FormSelectCustom';
import {
  ConversationListItem,
  ConversationManager,
  FiltersPanel,
  FormInputField,
  FormSelectCustom,
  FormSelectField,
  Loading,
  Pagination,
} from '../../components';
import './styles.css';
import config from '../../config';
import { useAppContext } from '../../context/AppContext';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { ConversationStatus } from '../../constants';
import SendMessagesModal from './SendMessagesModal';

type ConversationsFilters = {
  clientId?: number;
  externalCode?: string;
  status?: string[];
  dateSince?: string;
  dateUntil?: string;
};

function ConversationList({ navigate, params, toastManager }) {
  useDocumentTitle('Conversaciones');
  const { appState } = useAppContext();

  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [totalConversations, setTotalConversations] = useState(0);
  const [pageSelected, setPageSelected] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState<ConversationsFilters>({});
  const [filtersJustCleared, setFiltersJustCleared] = useState<boolean>(false);

  const [isSendingMessagesEnabled, setIsSendingMessagesEnabled] = useState(false);
  const [isSendMessageModalOpen, setIsSendMessageModalOpen] = useState(false);

  const conversationIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const CONVERSATIONS_PER_PAGE = 10;

  /**
   * Select Options for the status filter
   */
  const statusOptions: SelectCustomOption[] = Object.entries(ConversationStatus).map(
    ([key, value]) => {
      switch (value) {
        case ConversationStatus.initiated:
          return {
            value,
            label: 'Iniciada',
          };
        case ConversationStatus.inProgress:
          return {
            value,
            label: 'En curso',
          };
        case ConversationStatus.finishedOrder:
          return {
            value,
            label: 'Finalizada',
          };
        case ConversationStatus.finishedManual:
          return {
            value,
            label: 'Finalizada Manualmente',
          };
        case ConversationStatus.finishedClient:
          return {
            value,
            label: 'Finalizada por Cliente',
          };
        case ConversationStatus.finishedErrorWhatsapp:
          return {
            value,
            label: 'Error en Whatsapp',
          };
        case ConversationStatus.expired:
          return {
            value,
            label: 'Expirada',
          };
        default:
          return {
            value,
            label: key,
          };
      }
    },
  );

  useEffect(() => {
    loadConversations();

    return () => stopAutoReload();
  }, [pageSelected]);
  
  useEffect(() => {
    if (filtersJustCleared) {
      loadConversations();
      setFiltersJustCleared(false);
    }
  }, [filtersJustCleared]);

  useEffect(() => {
    // set the messaging as enabled only if there's a whatsapp connector enabled
    setIsSendingMessagesEnabled(
      appState.connectors.some((c) => c.type === 'whatsapp' && c.isEnabled),
    );
  }, [appState.connectors]);

  // Loads conversations and sets the state, with optional reload behavior
  function loadConversations(): void {
    if (isLoading) return;

    const offset = CONVERSATIONS_PER_PAGE * (pageSelected - 1);

    setIsLoading(true);

    // prepare pagination params
    const apiUrlParams = [
      `limit=${CONVERSATIONS_PER_PAGE}`,
      `offset=${offset}`,
      `sortField=createdAt`,
      `sortDir=DESC`,
    ];
    // prepare filter params
    if (filters.clientId) {
      apiUrlParams.push(`filter[clientId][eq]=${filters.clientId}`);
    }
    if (filters.externalCode) {
      apiUrlParams.push(
        `filter[externalCode][like]=${encodeURIComponent(`%${filters.externalCode}%`)}`,
      );
    }
    if (filters.status) {
      apiUrlParams.push(`filter[status][in]=${filters.status}`);
    }
    if (filters.dateSince) {
      apiUrlParams.push(`filter[createdAt][gte]=${filters.dateSince}T00:00:00`);
    }
    if (filters.dateUntil) {
      apiUrlParams.push(`filter[createdAt][lte]=${filters.dateUntil}T23:59:59`);
    }

    APIClient.get(`/conversations/details?${apiUrlParams.join('&')}`)
      .then((res) => {
        const { data, meta } = res.data;
        setConversations(data);
        setTotalConversations(meta.total);

        // Update the interval ref to use the latest filters and prevent immediate execution after manual filter changes
        if (conversationIntervalRef.current) {
          clearInterval(conversationIntervalRef.current);
        } 
        conversationIntervalRef.current = setInterval(
          loadConversations,
          parseInt(config.conversation.listUpdateInterval!),
        );
        
      })
      .catch((err) => {
        toastManager.add('Se detuvo la actualización automática del listado de conversaciones.', {
          appearance: 'error',
        });
        console.error('Error cargando listado de conversaciones. Se detuvo el auto-reload.', err);
        stopAutoReload();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function stopAutoReload() {
    if (conversationIntervalRef.current) {
      clearInterval(conversationIntervalRef.current);
      conversationIntervalRef.current = null;
    }
  }

  // if not numeric id, then redirect to conversations' root
  if (params?.id && !/^\d+$/.test(params?.id!)) {
    navigate('/conversations', { replace: true });
    return null;
  }

  function handleOnChangePaginationButton(page: number) {
    setPageSelected(page);
  }

  function handleSendMessageOpenClick() {
    setIsSendMessageModalOpen(true);
  }

  function handleSendMessageHide() {
    setIsSendMessageModalOpen(false);
  }

  //#region Filters ------------------------
  function handleFiltersClearClick() {
    setFilters({});
    setFiltersJustCleared(true);
  }

  function handleFiltersClick() {
    loadConversations();
  }

  const handleClientCustomLoadOptionsHandler = async (
    query: string,
  ): Promise<SelectCustomOption[]> => {
    const res = await APIClient.get(
      `/clientes?filter[razonSocial][like]=${encodeURIComponent(`%${query}%`)}`,
    );
    const dataFetched = res.data.data;
    const optionsFormatted = dataFetched.map((data: any) => ({
      value: data.id,
      label: data.razonSocial,
    }));
    return optionsFormatted;
  };

  function handleFilterFieldChange(event: any) {
    const { id, value } = event.target;

    switch (id) {
      case 'filterClientId':
        setFilters({ ...filters, clientId: value ? parseInt(value) : undefined });
        break;
      case 'filterSenderReference':
        setFilters({ ...filters, externalCode: value ? value : undefined });
        break;
      case 'filterStatus': {
        const { selectedValues } = event;
        setFilters({ ...filters, status: selectedValues ? selectedValues : undefined });
        break;
      }
      case 'filterDateSince':
        setFilters({ ...filters, dateSince: value || undefined });
        break;
      case 'filterDateUntil':
        setFilters({ ...filters, dateUntil: value || undefined });
        break;
      default:
        // do nothing
        console.warn(`Unexpected fitler input key: "${id}"`);
        break;
    }
  }
  //#endregion Filters ------------------------

  return (
    <>
      <SendMessagesModal show={isSendMessageModalOpen} onHide={handleSendMessageHide} />

      {/* FILTERS */}
      <FiltersPanel
        isDataLoading={isLoading}
        onFilterClick={handleFiltersClick}
        onClearClick={handleFiltersClearClick}
      >
        <Row>
          <Col md={6}>
            <FormSelectCustom
              id="filterClientId"
              label="Clientes"
              onLoadOptions={handleClientCustomLoadOptionsHandler}
              onChange={handleFilterFieldChange}
              selectedOption={filters.clientId === undefined ? null : undefined}
              clearable={true}
            />
          </Col>
          <Col md={6}>
            <FormInputField
              id="filterSenderReference"
              type="text"
              label="Referencia del remitente"
              value={filters.externalCode ?? ''}
              onChange={handleFilterFieldChange}
              placeholder="(Ej: '123456' o 'prueba@prueba.com')"
            />
          </Col>
          <Col md={6}>
            <FormSelectField
              id="filterStatus"
              choices={statusOptions}
              label="Estado"
              choiceIdField="value"
              choiceLabelField="label"
              required={false}
              multiple={true}
              defaultValue={filters.status ?? ''}
              onChange={handleFilterFieldChange}
            />
          </Col>
          <Col md={3}>
            <FormInputField
              id="filterDateSince"
              type="date"
              label="Desde"
              max={filters.dateUntil ? moment(filters.dateUntil).format('YYYY-MM-DD') : ""}
              value={filters.dateSince || ''}
              onChange={handleFilterFieldChange}
            />
          </Col>
          <Col md={3}>
            <FormInputField
              id="filterDateUntil"
              type="date"
              label="Hasta"
              min={filters.dateSince ? moment(filters.dateSince).format('YYYY-MM-DD') : ""}
              value={filters.dateUntil || ''}
              onChange={handleFilterFieldChange}
            />
          </Col>
        </Row>
      </FiltersPanel>

      <div>
        {isSendingMessagesEnabled && (
          <Button variant="primary" onClick={handleSendMessageOpenClick}>
            <FontAwesomeIcon icon={faWhatsapp} fixedWidth className="me-1" />
            Enviar mensajes
          </Button>
        )}
      </div>

      <div className="d-flex flex-column">
        {/* CONVERSATIONS */}
        <div className="d-flex flex-row">
          <div
            className="d-flex flex-column align-items-stretch flex-shrink-0 bg-white overflow-auto"
            style={{ width: '25vw', height: '80vh' }}>
            <span className="fs-5 fw-semibold">Conversaciones</span>
            <div className="list-group list-group-flush border-bottom scrollarea">
              {conversations.length > 0 ? (
                conversations.map((conversation) => (
                  <ConversationListItem key={conversation.id} conversation={conversation} />
                ))
              ) : (
                isLoading ? <Loading /> : <div>No hay conversaciones</div>
              )}
            </div>
            {totalConversations > 0 && (
              <Pagination
                pageSelected={pageSelected}
                totalPages={Math.ceil(totalConversations / CONVERSATIONS_PER_PAGE)}
                disabled={isLoading}
                onChange={handleOnChangePaginationButton}
              />
            )}
          </div>
          {/* MESSAGES */}
          {params?.id ? (
            <ConversationManager />
          ) : (
            <div className="m-auto w-fit fs-3">Seleccione una conversación.</div>
          )}
        </div>
      </div>
    </>
  );
}

export default withToastManager(withRouter(ConversationList));
