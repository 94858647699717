import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faUndo, faSave } from '@fortawesome/free-solid-svg-icons';
import { useToasts } from 'react-toast-notifications';
import Loading from './Loading';

export type FormButtonType = 'submit' | 'button' | 'reset' | undefined;

type ActionButtonConfig = {
  text: string;
  type: FormButtonType;
  variant: string;
  classes?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  hidden?: boolean;
  action?: () => void;
};

interface EntityEditFormProps {
  children: React.ReactNode;
  addMode?: boolean;
  isEditable?: boolean;
  isModified?: boolean;
  buttons?: ActionButtonConfig[];
  initializedEntity?: any | (() => any);
  onLoadForm?: () => void;
  onRetrieveEntity?: () => void;
  onSaveEntity?: (entityToSave: any, actionType?: FormButtonType) => void;
  /** @deprecated Use `onPrepareToSave` instead */
  prepareToSave?: (entity: any, actionType?: FormButtonType) => any;
  onPrepareToSave?: (entity: any, actionType?: FormButtonType) => any;
  onValidate?: (entity: any, actionType?: FormButtonType) => string | undefined;
}

export default function EntityEditForm({
  addMode = false,
  initializedEntity,
  isEditable = true,
  isModified = false,
  buttons = [
    {
      text: 'Guardar',
      type: 'submit',
      variant: 'primary',
      classes: 'me-1',
      icon: <FontAwesomeIcon icon={faSave} fixedWidth className="me-1" />,
    },
  ],
  children,
  onLoadForm,
  onSaveEntity,
  onRetrieveEntity,
  prepareToSave,
  onPrepareToSave,
  onValidate,
}: EntityEditFormProps) {
  const { addToast } = useToasts();

  const [actionType, setActionType] = useState<FormButtonType>();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isModifiedInternal, setIsModifiedInternal] = useState(isModified);
  const [entityToSave, setEntityToSave] = useState<any>({});
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    async function initialize() {
      if (onLoadForm) {
        await onLoadForm();
      }

      if (addMode) {
        // undo the Loading mode
        if (initializedEntity) {
          let newEntity = initializedEntity;
          if (typeof initializedEntity === 'function') {
            newEntity = await initializedEntity();
          }
          setEntityToSave(newEntity);
        }

        setIsLoading(false);
      } else {
        try {
          if (onRetrieveEntity) {
            await onRetrieveEntity();
          }
          setIsLoading(false);
        } catch (err) {
          console.error(err);
        }
      }
    }

    initialize().catch((err) => console.error('Error initializing the EntityEditForm', err));
  }, []);

  /**
   * Save the item
   */
  const onSaveSubmit = async (event) => {
    event.preventDefault();

    let entityToSaveLocal = entityToSave;

    // if it's in the process of saving, do nothing else
    if (isSaving) {
      return;
    }

    if (isSubmitButtonClicked && isEditable) {
      try {
        // Prepare entity to save
        if (onPrepareToSave) {
          entityToSaveLocal = onPrepareToSave(entityToSaveLocal, actionType);
        } else if (prepareToSave) {
          entityToSaveLocal = prepareToSave(entityToSaveLocal, actionType);
        }
        let validationMessage: string | undefined = undefined;
        if (onValidate) {
          validationMessage = onValidate(entityToSaveLocal, actionType);
        }
        if (validationMessage && validationMessage.length !== 0) {
          addToast(validationMessage, { appearance: 'warning' });
        } else {
          // if not addMode and nothing to save, notify and do nothing
          if (entityToSaveLocal == null || Object.keys(entityToSaveLocal).length === 0) {
            addToast('No se realizaron cambios', {
              appearance: 'info',
              autoDismiss: true,
            });
            return;
          }

          setIsSaving(true);
          if (onSaveEntity) {
            await onSaveEntity(entityToSaveLocal, actionType);
          }
          setEntityToSave({});
          setIsModifiedInternal(false);
        }
      } catch (err) {
        console.error('Error al guardar la entidad.', err);
        const castedErr = err as any;
        let errorMessage = castedErr.message;
        if (castedErr.response && castedErr.response.data && castedErr.response.data.message) {
          errorMessage = castedErr.response.data.message;
        }
        addToast(`Ocurrió un error: "${errorMessage}"`, {
          appearance: 'error',
        });
      } finally {
        setIsSubmitButtonClicked(false);
        setIsSaving(false);
      }
    }
  };

  /**
   * Form field input changed
   */
  const onFormChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLFormElement>,
  ) => {
    const eventAsChange = event as React.ChangeEvent<HTMLInputElement>;
    const { value, name, type } = eventAsChange.target;

    let valueToSave: string | boolean | null = value;
    if (type === 'checkbox') {
      valueToSave = eventAsChange.target.checked;
    } else if (type === 'select-one') {
      if (valueToSave === '') {
        // assume '' as empty
        valueToSave = null;
      }
    } else if (type === 'text' && name === '') {
      return;
    }

    const names = name.split('.');
    // support up to 3 levels
    if (names.length > 1) {
      // if the object is not created, do it
      if (!entityToSave[names[0]]) {
        entityToSave[names[0]] = {};
      }

      if (names.length > 2) {
        // if the object is not created, do it
        if (!entityToSave[names[0]][names[1]]) {
          entityToSave[names[0]][names[1]] = {};
        }
        entityToSave[names[0]][names[1]][names[2]] = valueToSave;
      } else if (names.length === 2) {
        entityToSave[names[0]][names[1]] = valueToSave;
      }
    } else {
      entityToSave[name] = valueToSave;
    }

    setEntityToSave(entityToSave);
    setIsModifiedInternal(true);
  };

  const renderSubmitButtons = (buttons: ActionButtonConfig[]) => {
    const buttonsElements = buttons.filter(b=> !b.hidden).map((but) => (
      <Button
        key={but.text}
        className={but.classes}
        variant={but.variant}
        onClick={() => {
          setIsSubmitButtonClicked(true);
          
          if (but.action && (!formRef.current || formRef.current.checkValidity())) {
            but.action();
          } 
          
          setActionType(but.type);
        }}
        type={but.type}
        disabled={!isModifiedInternal || isSaving || but.disabled}>
        {!isSaving ? (
          but.icon
        ) : (
          <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin className="me-1" />
        )}
        {but.text}
      </Button>
    ));

    return (
      <>
        {isEditable && buttonsElements}
        <Button variant="secondary" onClick={() => window.history.back()} disabled={isSaving}>
          <FontAwesomeIcon icon={faUndo} fixedWidth className="me-1" />
          Volver
        </Button>
      </>
    );
  };

  return (
    <Form onSubmit={onSaveSubmit} onInput={onFormChange} ref={formRef}>
      {isLoading && <Loading />}
      {!isLoading && children}

      <div className="my-2">{renderSubmitButtons(buttons)}</div>
    </Form>
  );
}
