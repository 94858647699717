import React from 'react';
import { Nav, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDolly,
  faFileAlt,
  faList,
  faComments,
  faShoppingCart,
  faSuitcase,
  faUsers,
  faUserTie,
  faFileInvoiceDollar,
  faRulerCombined,
  faIndustry,
  faPlug,
} from '@fortawesome/free-solid-svg-icons';
import './SideMenu.scss';
import projectPackage from '../../package.json';

function SideMenu() {
  return (
    <Nav className="col-md-2 d-none d-md-block bg-light sidebar">
      <div className="sidebar-sticky">
        <div className="flex-column">
          <NavItem>
            <LinkContainer to="/orders">
              <Nav.Link>
                <FontAwesomeIcon icon={faFileAlt} fixedWidth className="me-2" />
                Pedidos
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/conversations">
              <Nav.Link>
                <FontAwesomeIcon icon={faComments} fixedWidth className="me-2" />
                Conversaciones
              </Nav.Link>
            </LinkContainer>
          </NavItem>
        </div>

        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
          <span>Productos</span>
        </h6>
        <div className="flex-column">
          <NavItem>
            <LinkContainer to="/products">
              <Nav.Link>
                <FontAwesomeIcon icon={faShoppingCart} fixedWidth className="me-2" />
                Productos
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/marcas">
              <Nav.Link>
                <FontAwesomeIcon icon={faList} fixedWidth className="me-2" />
                Marcas
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/product-categories-1">
              <Nav.Link>
                <FontAwesomeIcon icon={faList} fixedWidth className="me-2" />
                Categoría 1
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/product-categories-2">
              <Nav.Link>
                <FontAwesomeIcon icon={faList} fixedWidth className="me-2" />
                Categoría 2
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/product-categories-3">
              <Nav.Link>
                <FontAwesomeIcon icon={faList} fixedWidth className="me-2" />
                Categoría 3
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/units">
              <Nav.Link>
                <FontAwesomeIcon icon={faRulerCombined} fixedWidth className="me-2" />
                Unidades
              </Nav.Link>
            </LinkContainer>
          </NavItem>
        </div>

        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
          <span>Clientes</span>
        </h6>
        <div className="flex-column">
          <NavItem>
            <LinkContainer to="/clientes">
              <Nav.Link>
                <FontAwesomeIcon icon={faUserTie} fixedWidth className="me-2" />
                Clientes
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/puntos-entrega">
              <Nav.Link>
                <FontAwesomeIcon icon={faDolly} fixedWidth className="me-2" />
                Puntos de Entrega
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/zonas">
              <Nav.Link>
                <FontAwesomeIcon icon={faDolly} fixedWidth className="me-2" />
                Zonas
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/listas-precio">
              <Nav.Link>
                <FontAwesomeIcon icon={faFileInvoiceDollar} fixedWidth className="me-2" />
                Listas de Precio
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          {/* <NavItem>
            <LinkContainer to="/combo">
              <Nav.Link>
                <FontAwesomeIcon icon={faFileInvoiceDollar} fixedWidth className="me-2" />
                Combos
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/modificadores-precio">
              <Nav.Link>
                <FontAwesomeIcon icon={faFileInvoiceDollar} fixedWidth className="me-2" />
                Modif. de Precio
              </Nav.Link>
            </LinkContainer>
          </NavItem> */}
          <NavItem>
            <LinkContainer to="/cliente-tipos">
              <Nav.Link>
                <FontAwesomeIcon icon={faList} fixedWidth className="me-2" />
                Tipos
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/agents">
              <Nav.Link>
                <FontAwesomeIcon icon={faSuitcase} fixedWidth className="me-2" />
                Vendedores
              </Nav.Link>
            </LinkContainer>
          </NavItem>
        </div>

        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
          <span>Administración</span>
        </h6>
        <div className="flex-column">
          <NavItem>
            <LinkContainer to="/usuarios">
              <Nav.Link>
                <FontAwesomeIcon icon={faUsers} fixedWidth className="me-2" />
                Usuarios
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/companies">
              <Nav.Link>
                <FontAwesomeIcon icon={faIndustry} fixedWidth className="me-2" />
                Compañía
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem>
            <LinkContainer to="/connectors">
              <Nav.Link>
                <FontAwesomeIcon icon={faPlug} fixedWidth className="me-2" />
                Integraciones
              </Nav.Link>
            </LinkContainer>
          </NavItem>
        </div>

        <div className="my-3 px-3">
          <small className="text-muted">{`v${projectPackage.version}`}</small>
        </div>
      </div>
    </Nav>
  );
}

export default SideMenu;
